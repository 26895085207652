.accordion-component{
  cursor: pointer;
  .card{
    &:first-of-type{
      border-radius: 0;
    }
    &:last-of-type{
      border-radius: 0;
    }
    .card-header.black{background: #020202; color: #FFFFFF}
    .card-header.white{background: #FFFFFF; color: #020202}
    .card-header.gray{background: #F8F8F8; color: #020202}
    .card-header{
      h3{
        padding: 0;
      }
      h3.text-right{
        content: "+";
        font-size: 2rem;
        transition: transform 0.3s ease;
      }
    }
    .card-header.collapsed{
      h3.text-right::after{
        content: "+";
      }
    }
    .card-header:not(.collapsed){
      h3.text-right::after{
        content: "–";
      }
    }
    .card-body.black{background: #020202; color: #FFFFFF}
    .card-body.white{background: #FFFFFF; color: #020202}
    .card-body.gray{background: #F8F8F8; color: #020202}
  }

}