@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-Light.ttf) format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-ExtraLight.ttf) format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-Regular.ttf) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-Medium.ttf) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url(#{$fonts}Raleway/Raleway-Bold.ttf) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url(#{$fonts}Mulish/static/Mulish-Light.ttf) format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url(#{$fonts}Mulish/static/Mulish-ExtraLight.ttf) format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url(#{$fonts}Mulish/static/Mulish-Regular.ttf) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url(#{$fonts}Mulish/static/Mulish-Medium.ttf) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url(#{$fonts}Mulish/static/Mulish-Bold.ttf) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}