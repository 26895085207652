.cmp_cards{
    .container-sm-0{
        .section-header {
            @include container;
            @media (min-width: $desktop) {
                @include container--disabled;
            }
        }
    }
    .section-header{
        .col {
            @include pb(2);
        }
        .col:empty {
            padding-bottom:0;
            display: none;
        }
    }
    .section-header + .listingItems{
        .title{
            @media (max-width: $desktop) {
                font-size: 16px;
            }
        }
    }
    .slick-arrow{
        @media (min-width: $tablet-P) {
            &.slick-prev{
                margin-left: -$space--md;
            }
            &.slick-next{
                margin-right: -$space--md;
            }
        }
        @media (min-width: $desktop) {
            &.slick-prev {
                margin-left: -$space--lg;
            }
            &.slick-next {
                margin-right: -$space--lg;
            }
        }
    }
    /* the slides */
    .slick-slide {
        margin: 0 $space--md;
        @media (max-width: 767.9px) {
            margin: 0px;
        }
    }
    .slick-dots li button:before{
        font-size: 10px;
    }
    /* the parent */
    .slick-list {
        margin: 0 -$space--md;
    }

    .cardItem,
    .listingItems{
        .video {
            video {
                aspect-ratio: 1.39;
                max-width: 100%;
            }
            .plyr{
                aspect-ratio: 1.39;
            }
        }
    }
    .gallery {
        .slideShow {
            margin-top: 25px;
            padding-bottom: 25px;
        }
    }
    &.bg-default {
        .gallery {
            .slideShow {
                .slick-dots li button:before {
                    color: $white;
                }
            }
        }
    }
    &:not(.bg-default) {
        .gallery {
            .slideShow {
                .slick-dots li button:before {
                    color: $black;
                }
            }
        }
    }

}
.cmp_cards{
    &.tpl_cardsDefault,
    &.tpl_columnsCards{
        .listingItems{
            .item:first-child:nth-last-child(1),
            .item:first-child:nth-last-child(1) ~ .item,
            .item:first-child:nth-last-child(2),
            .item:first-child:nth-last-child(2) ~ .item{
                max-width: 460px;
                margin: 0 auto;
            }

            .cardItem {
                height: 100%;
                .textBlock {
                    a.action-link {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    &.tpl_cardsDefault,
    &.tpl_columnsCards,
    &.tpl_square{
        .cardItem {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background-color: rgba(0, 0, 0, 0);
                max-width: none;
            }
            .image,
            .video {
                padding-bottom: 25px;

                img {
                    width: 100%;
                    height: auto;
                }

                img.box--bigger {
                    max-width: 640px;
                }

            }

            .textBlock {
                padding-left: 37px;
                padding-right: 37px;
                @include pb(3);
                max-width: 460px;
                @media (min-width: $tablet-P) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }

            .title {
                @include letterSpacing(lg);
            }

            .text {
                p {
                    line-height: 1.5;
                }
            }
        }
        .slick-arrow{
            top:33%;
        }
    }
    &.tpl_cardsDefault{
        .title {
            padding-bottom: 15px;
        }
        .js-slider-cards-component {
            @media (min-width: $tablet-P) {
                .slick-track {
                    display: flex;
                }
            }

            .slick-list {
                margin: 0 -10px;
            }
            .slick-slide {
                height: inherit;
                .cardItem {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: auto;
                }
                .textBlock {
                    a.action-link {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 15px;

                    }
                }
            }
        }
        .js-slider-cards-component.center-mode{
            .slick-list {
                margin: 0 15px;
            }
        }
        .js-slider-cards-component.paddingSquareNoCarousel{
            padding: 0 75px;
        }
        .listingItems {
            display: flex;
            .cardItem {
                display: flex;
                flex-direction: column;
                height: 100%;
                .textBlock {
                    a.action-link {
                        position: absolute;
                        bottom: 0;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    &.tpl_columnsCards{
        .listingItems{
            .cardItem{
                @media (max-width: $tablet-P){
                    margin: 0 25px;
                }
            }       
        }  
    }
    &.tpl_square{
        @include pv-50;
        .cardItem {
            max-width: 460px;
            margin: 0 auto;
        }
    }
    &.tpl_grid {
        .section-header{
            padding-top: 50px;
        }
        .section-header ~ .listingItems .verticalSectionItems{
            padding-top: 0;
        }
        .verticalSectionItems {
            @media (min-width: $desktop) {
                padding: 50px 0;
            }

            /* the slides */
            .slick-slide {
                margin-left: 27px;
//                 @media (min-width: $tablet-P) and (max-width:$desktop) {
//                     display: flex;
//                     gap: 25px;
//                     width: unset!important;
//                 }
            }

            /* the parent */
            .slick-list {
                margin-left: -27px;
            }

            .cardItem {
                min-height: 640px;
                display: flex;
                justify-content: unset;
                flex-direction: column;
                @media (min-width: $desktop) {
                    justify-content: space-around;
                    flex-direction: row;
                    padding: 50px 0;
                }
                .gallery {
                    width: 100%;
                    @media (min-width: $desktop) {
                        max-width: 300px;
                    }
                    .image,
                    .video{
                        padding-bottom: 25px;
                        @media (min-width: $desktop) {
                            padding-bottom: inherit;
                        }

                        img {
                            width: 100%;
                        }
                    }
                }

                .textBlock {
                    padding: 0 37px 50px 37px;
                    width: 100%;
                    @media (min-width: $tablet-P) {
                        max-width: 450px;
                        padding: 0 0 50px;
                    }
                    @media (min-width: $desktop) {
                        padding: 0 $space--sm;
                    }
                }
            }
        }
    }
    &.tpl_minimal {
        .listingItems {
            display: flex;
            flex-direction:column;
            @media (min-width: $tablet-P) {
                flex-direction:row;
                justify-content: space-around;
            }
            .cardItem {
                padding: 30px;
                .textBlock {
                    padding-right: 0;
                    position: relative;

                    max-width: 400px;

                    @media (min-width: $tablet-L) {
                        width:$textBlock-width;
                        margin: 0 auto;
                    }
                    &:before {

                        content: '';
                        width: 1px;
                        height: 100%;
                        background-color: $white;
                        position: absolute;
                        margin-left: -45px;
                        left: unset;
                        top: 0%;
                        @media (max-width: $mobile) {
                            margin-left: -15px;
                        }
                        @media (min-width: $tablet-L) {
                            margin-left: 0px;
                            height: 120%;
                            left: -50px;
                            top: -10%;
                        }

                    }
                    .title{
                    }
                }
            }
        }
        &.bg-alternative{
            .listingItems {
                .cardItem {
                    .textBlock {
                        &:before {
                            background-color: $color-dark;
                        }
                    }
                }
            }

        }
    }
    &.tpl_twoColumn{
        .listingItems{
            max-width: $max-screen-size;
            margin: 0 auto;
            .row{
                @media (min-width: $tablet-P) {
                    &:nth-child(-n+3) {
                        .item {
                            max-width: 33%;
                        }
                    }
                    &:nth-child(-n+4) {
                        .item {
                            max-width: 25%;
                        }
                    }
                    &:nth-child(-n+2) {
                        .item {
                            max-width: 50%;
                        }
                    }
                }
            }

            .item{
                display: flex;
                flex-direction: column;
                @include paddingY(3);
                transition: .3s all ease-in-out;
                text-align:center;
                .textBlock {
                    @include pb(2);
                    .title {
                        @include newtitle(md,xs);
                        padding-bottom: 0;
                    }

                    .cta {
                    }
                }
                .gallery {
                    min-width: 100%;
                    @media (min-width: $desktop) {
                        min-width: 50%;
                    }
                    .image,
                    .video {
                        transition: .3s all ease-in-out;
                        transform: translateY(0px);
                        padding-top: 20px;
                        picture, img {
                            max-width: 420px;
                            width: 100%;
                            @media (min-width: $tablet-P) {
                                max-width: 640px;
                            }
                        }
                        img{
                            @media (min-width: $tablet-P) {
                                padding: 20px;
                            }
                        }
                    }
                }
                @media (min-width: $desktop) {
                    &:hover {
                        transition: .3s all ease-in-out;
                        transition-delay: .3s;
                        background-color: white;

                        .title {
                            transition: .3s all ease-in-out;
                            color: $black;
                            font-weight: bold;
                            text-decoration: unset;

                            a {
                                transition: .3s all ease-in-out;
                                color: $black;
                                font-weight: bold;
                                text-decoration: unset;
                            }
                        }

                        .cta {
                            transition: .3s all ease-in-out;
                            transition-delay: .3s;

                            a.action-link {
                                transition: .3s all ease-in-out;
                                transition-delay: .3s;
                                color: $t-color-secondary;
                                &:before {
                                    transition: .3s all ease-in-out;
                                    border-top: 1px solid;
                                }
                            }
                        }

                        .image {
                            @media (min-width: $desktop) {
                                transition: .3s all ease-in-out;
                                transition-delay: .3s;
                                transform: translateY(-20px);
                            }

                        }
                    }
                }

            }
        }
    }
    &.tpl_focus{}
    &.tpl_partners{
        .listingItems {
            /* the parent */
            .slick-list {
                margin: 0;
            }
        }
    }
    &.tpl_4columns {
        .cardItem {

            height: 100%;
            max-width: 460px;
            margin: 0 auto;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background-color: rgba(0, 0, 0, 0);
                max-width: none;
            }

            .image,
            .video{
                padding-bottom: 25px;

                img {
                    width: 100%;
                    height: auto;
                }

                img.box--bigger {
                    max-width: 640px;
                }

            }

            .textBlock {
                padding-left: 37px;
                padding-right: 37px;
                @include pb(3);
                max-width: 460px;
                @media (min-width: $tablet-P) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
                a.action-link {
                    position: absolute;
                    bottom: 0;
                    margin-bottom: 15px;
                }
            }

            .title {
                @include letterSpacing(lg);
                @include newtitle(sm);
                font-weight: 500;
            }

            .text {
            }
        }
        .slick-arrow{
            top:40%;
        }
    }
    &.tpl_icons{
        &.align--center{
            .listingItems {
                @media (min-width: $desktop) {
                    width: calc(60% - 15px);
                    .cardItem{
                        max-width: 30%;
                    }
                }
                justify-content: center;

            }
        }
        .listingItems{
            width:100%;
            @media (min-width: $desktop) {
                width:calc(100% - 15px);
            }
            margin:0 auto;
            .link{
                @media (min-width: $tablet-P) {
                    display: contents;
                }
            }
            .cardItem{
                padding:50px;
                margin-bottom: $space--md;
                @media (min-width: $tablet-P) {
                    margin-left:10px;
                    margin-right:10px;
                }
            }
            .cardItem{
                .gallery{
                    margin-bottom: $space--md;
                    margin: 0 auto;
                    @media (min-width: $desktop) {
                        width: 30%;
                    }
                    .image{
                        display: block;
                    }
                    .image--hover{
                        display: none;
                    }
                }
                &:hover{
                    .gallery{
                        .image{
                            display: none;
                        }
                        .image--hover{
                            display: block;
                        }
                    }
                }
            }
        }
        .slick-arrow{
            &:before{
                color: var(--color-sustainability-dark);
                opacity: 1;
            }
        }
        .slick-arrow{
            @media (min-width: $tablet-P) {
                &.slick-prev{
                    margin-left: 20px;
                }
                &.slick-next{
                    margin-right: 20px;
                }
            }
            @media (min-width: $desktop) {
                &.slick-prev {
                    margin-left: 20px;
                }
                &.slick-next {
                    margin-right: 20px;
                }
            }
        }
    }
    &.tpl_transition{
        .cardItem{
            .text{
                p{
                    display: none;
                    &:first-of-type{
                        display: block;
                    }
                }
            }
            &:hover{
                .gallery{
                    .image{
                        height: 0;
                    }
                }
                p{
                    display: block;
                }
            }
        }
    }
}

.cards-partners{
    .section-header{
        @include container;
        @media (min-width: $desktop) {
            @include container--disabled;
        }
        .title{
            @include newtitle();
            padding: $space--md 0 $space--lg 0;
            font-weight: 400;
            line-height: 38px;
            letter-spacing: 1.28px;
        }
    }
    .card {
        background-color: transparent;
        border-color: transparent;
        .title{
            text-align: center;
            padding-bottom: $space--sm;
        }
        .image {
            height: 200px;
            img{
                height: 100%;
                
                @media (max-width: $tablet-P) {
                    width: auto;
                    margin: 0 auto;
                }
            }
        }
        .link{
        }
    }
}


/*FOODSERVICE*/
.foodservice{
    .cmp_cards {
        &.tpl_cardsDefault,
        &.tpl_4columns{
            .cardItem{
                max-width: unset;
            }
        }
        &.tpl_twoColumn {
            .listingItems {
                .item {
                    .title{
                        a{
                            color:$t-color-primary;
                        }
                    }
                    .cta {
                        a.action-link {
                            color:$t-color-primary;
                            background-color: transparent;
                            &:before {
                                content: "";
                                display: inline-block;
                                margin-right: 20px;
                                border-top: 1px solid;
                                width: 40px;
                                vertical-align: middle;
                                transition: all 1s ease-in-out;
                            }
                            &:hover{
                                &:before{
                                    margin-right: 30px;
                                    width: 50px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &:not(.tpl_twoColumn) {
            a.action-link {
                color: $color-primary;
            }
        }
        &.tpl_minimal {
            &.bg-default{
                .listingItems {
                    .cardItem {
                        .textBlock {
                            &:before {
                                background-color: $color-dark;
                            }
                        }
                    }
                }

            }
        }
    }
}

.square-img {
    width: 350px !important;
    height: 350px;
}

@media (min-width: $desktop) {
    .square-img {
        width: 650px !important;
        height: 650px;
    }

    .padding-left-square{
        padding-left: 185px !important;
    }

    .padding-right-square{
        padding-right: 185px !important;
    }

}

.padding-left-square{
    padding-left: 15px;
}

.padding-right-square{
    padding-right: 15px;
}

//.container-fluid{
//    >.center-mode{
//        .cardItem{
//            width: 505px;
//        }
//    }
//}
//
//@media (max-width: $desktop-XL){
//    .container-fluid{
//        >.center-mode{
//            .cardItem{
//                width: 300px !important;
//            }
//        }
//    }
//}
//
//@media (max-width: $tablet-L){
//    .container-fluid{
//        >.center-mode{
//            .cardItem{
//                width: 200px !important;
//            }
//        }
//    }
//}