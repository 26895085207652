@mixin a-symmetric-format($m-b:"", $f-size:"", $l-height:"") {
  margin-bottom: $m-b;
  font-size: $f-size;
  line-height: $l-height;
}


.cmp_generalText {
  position: relative;
  padding-top: 35px;
  padding-bottom: 35px;

  @media (min-width: $desktop) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .title {
    @include pb(3);
    @include letterSpacing(lg);
    font-weight: 500;
    @media (min-width: $desktop) {
      font-weight: 400;
    }
  }

  &.pv-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.cmp_generalText {
  &.tpl_textDefault{}
  &.tpl_symmetricText {
    .text {
      @media (min-width: $tablet-P) {
        column-gap: 36px;
        column-count: 2;
        -webkit-column-fill: balance;
        -moz-column-fill: balance;
        column-fill: balance;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &.tpl_asymmetricText {
    .textBlock {
      @media (min-width: $desktop) {
        justify-content: space-between;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex-wrap: initial;
      }

    }

    .title {
      font-weight: 500;

      @media (min-width: $desktop) {
        font-weight: 400;
        line-height: 40px;
      }
    }

    .text {
      max-width: $el-max-width;
      width: 100%;
      @media (min-width: $desktop) {
        float: right;
      }
      h6 {
        margin-bottom: 5vw;

        @media (min-width: $tablet-P) {
          margin-bottom: 2vw
        }

        @media (min-width: $tablet-L) {
          margin-bottom: initial;
        }
      }

      p {
        &:last-of-type {
          margin-bottom: 0px;
        }
      }

      strong {
        font-family: $font-primary;
      }

      span {
        border-bottom: .5px solid $white;
        width: 39px;
      }

      .action-link {
        margin-top: 34px;
      }

    }
  }
  &.tpl_asymmetricTwoColumnText {

    .text {
      @media (min-width: $desktop) {
        -moz-column-gap: 36px;
        column-gap: 36px;
        -moz-column-count: 2;
        column-count: 2;
        -moz-column-fill: balance;
        column-fill: balance;
      }

    }

  }
  &.tpl_focus {
    .textBlock{
      font-family: $font-secondary;
      font-size: 30px;
      font-weight: 300;
      line-height: 40px;
      position: relative;
      p,
      .title{
        max-width: 715px;
        margin: 0 auto;
        text-align: center;
        &:before {
          content: "";
          left: 50%;
          top:-20px;
          transform: translateX(-50%);
          font-size: 86px;
          display: inline-block;
          position: absolute;
          width: 177px;
          height: 1px;
          border: 1px solid;
        }
      }
    }
  }
  &.tpl_quote {
    text-align: center;
    .textBlock{
      position: relative;
      p {
        quotes: "“" "”" "‘" "’";
        font-family: $font-secondary;
        font-style:italic;
        position: relative;

        max-width: 715px;
        margin: 0 auto;

        &:before, &:after {
          font-size: 50px;
          font-family: $font-secondary;
          font-style: italic;
          display: inline-block;
          position: absolute;
          opacity: .23;
        }
        &:before {
          content: open-quote;
          left: 50%;
          top:-30px;
          transform: translateX(-50%);
          @media (min-width: $desktop) {
            left: unset;
            top:0%;
            margin-left: -25px;
          }
        }
        &:after {
          content: close-quote;
          left: 50%;
          top:100%;
          transform: translateX(-50%);
          @media (min-width: $desktop) {
            transform: translate(0%);
            transform: translateY(-70%);
            top:100%;
            left: unset;
          }
        }
      }
    }

    .generalText__divisor {
      width: 10%;
      min-width: 50px;
      height: 2px;
      border-top: 2px solid $grey;
      border-radius: 2px;
      position: absolute;
      left: 50%;

      &.generalText__divisor--top {
        top: 0px;
      }

      &.generalText__divisor--bottom {
        bottom: 0px;
      }

    }

  }
  &.tpl_alertText{
    color:var(--color-primary);
    .textBlock{
      padding:35px;
    }
  }
}

.foodservice{
  .textBlock{
    margin-top: 100px;
  }
  .textBlock.box--bigger{
    margin-top: 0;
  }
}