 .story-bg {
	//background-color: $black;
}

.storyHeader {
	@media (min-width: $tablet-L) {
		padding-top:130px;
	}
	&__title {
		font-family: $font-secondary;
		margin-bottom: 40px;
		font-size: 26px;
		padding-top: 50px;
		line-height: 42px;
		font-weight: 200;
		@media (min-width: $desktop) {
			padding-top: 0;
			font-size: 40px;
			margin-bottom: 0px;
			font-weight:200;
		}
		@media(min-width: $desktop-XL){
			margin-top: 0px;
		}
	}
	&__rootTitle {
		/* Style for "Stories" */
		display: inline-block;
		height: 79px;
		color: $grey;
		font-family: $font-secondary;
		font-size: 26px;
		font-weight: 300;
		line-height: 42px;
		padding-top: 50px;
		@media (min-width: $desktop) {
			padding-top: 0;
			font-size: 40px;
			height: auto;
			margin-bottom: 0px;
		}
	}
	&__introduction{
		width: auto;
		color: $white;
		font-family: $font-primary;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.64px;
		line-height: 30px;
		min-height: 34px;
		margin-top: 17px;
		margin-bottom: 1rem;
	}
}
.stories-grid {
	width: 100%;
	margin-left:inherit;
	margin-left: 0px;
	width: 100%;
	margin-top: 50px;
	@media (min-width: $desktop) {
		width: auto;
	}
	@media (min-width: $desktop-XL) {
		width: calc(100% + 20px);
		margin-left: -10px;
	}
	&__item {
		width: 100%;
		padding-bottom: 20px;
		@media (min-width: $mobile) {
			width: 32%;
		}
		@media (min-width: $desktop) {
			padding-bottom: inherit;
			width: 20%;
			min-width: auto;
		}
		@media (min-width: $desktop-XL) {
			padding-bottom: inherit;
			width: 20%;
		}
		flex-wrap: wrap;
		//background-color: $black;
		box-sizing: border-box;
		&--double {
			width: 100%;

			@media (min-width: $mobile) {
				width: 65%;
			}
			@media (min-width: $desktop) {
				width: 40%;
			}
		}
		&__desc {
			padding: 10px;
		}
	}
}

.storyItem__borderBlock{
		width: 100%;
		height: 100%;
		margin: inherit;
		background-color: $light-black;
		@media (min-width: $desktop) {
			width: calc(100% - 20px);
			height: calc(100% - 20px);
			margin:10px;
		}
		&--light {
			background-color: $white;
			>div {
				background-color: $white;
				a {
					color: $black;
				}
			}
			.storyItem__textBlock{
				color: $black;
				.storyItem__category{
					&::after{
						border-top: 2px solid $light-black;
					}
				}
			}
		}
		&:hover{
			.storyItem{
				&__image {
					img {
						transform: scale(1.1, 1.1);
						transition: .2s;
						@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
							transform: translate(-50%, 0%);
						}
					}
				}
				&__category{
					&::after{
						width: 80px;
						transition: width .2s ease-in;
					}
				}
				
			}
			
		}
	}
	.storyItem__image,
	.storyItem__image img,
	.storyItem__image_default,
	.storyItem__image_default img
	{
		width: 100%;
		height: 315px;
		overflow: hidden;

		@media (min-width: $tablet-L) {
			height: 370px;
		}
		@media (min-width: $desktop) {
			height: 280px;
		}
	}
	
	.storyItem__image {
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			position: relative;
			width: 100%;
			overflow: hidden;
		}
		
	}
	.storyItem__image img{
		width: 100%;
		object-fit: cover;
		transform: scale(1, 1);
		transition: 0.2s ease-in;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			height: 100%;
			width: auto;
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0%);
		}
		
	}

	.storyItem__image_default {
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		background-size: 70%;
		background-image: url('#{$images}/logo.png');
	}
	
	
	.storyItem__textBlock{
		text-align: center;
		background-color: $light-black;
		padding:27px 5px;
		color: $white;
		@media (min-width: $desktop) {
			padding:27px 20px;
		}
		&--dark{
			color: $light-black;
		}
	}
	.storyItem__category{
		font-family: $font-secondary;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 2.88px;
		line-height: 24px;
		text-transform: uppercase;
		display: block;
		text-align: center;
		&::after{
			content: ' ';
			display: block;
			height: 1px;
			width: 38px;
			min-width: 50px;
			margin: 20px auto;
			text-indent: -9999px;
			border-top: 2px solid $white;
			border-radius: 2px;
			transition: width .2s ease-in;
		}
	}
	.storyItem__title{
		height: 69px;
		overflow-y: hidden;
		overflow-x: hidden;
		font-family: $font-secondary;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		text-transform: uppercase;
	}

.highlightStory{
	margin-top: 40px;
	@media (min-width: $tablet-P) {
		margin-top: 50px;
	}
	&__image {
		position: relative;
		margin-left: -37px;
		width: 100vw;
		height: 226px;
		@media (min-width: $tablet-P) {
			position: inherit;
			margin-left: inherit;
			width: inherit;
			height: auto;
		}
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			position: relative;
			width: 100%;
			overflow: hidden;
		}

		img{
			width: 100%;
			height: 226px;
			object-fit: cover;
			@media (min-width: $tablet-P) {
				height: auto;
			}
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				height: auto;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
	&__textBlock{
		text-align: center;
		height: 190px;
		background-color: $white;
		padding:27px 5px;
		color: $light-black;
		position: relative;
		margin-top: -72px;
		margin-bottom: 20px;
		@media (min-width: $desktop) {
			padding:27px 20px;
			width: 620px;
			min-height: 168px;
			margin: -138px auto 20px;
		}
		&--dark{
			color: $light-black;
		}
		.promotion__label{
			&:after{
				border-top: 1px solid $color-secondary!important;
			}

		}
	}
	&__category{
		font-family: $font-secondary;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 2.88px;
		line-height: 24px;
		text-transform: uppercase;
		&::after{
			content: ' ';
			display: block;
			height: 1px;
			width: 38px;
			min-width: 50px;
			margin: 20px auto;
			text-indent: -9999px;
			border-top: 2px solid $light-black;
			border-radius: 2px;
		}
	}
	&__title{
		height: 70px;
		overflow-y: hidden;
		font-family: $font-secondary;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.96px;
		line-height: 24px;
		text-transform: uppercase;
	}
}


.link-list {

}

.grid__col-sizer{
	width: 20%;

	@media (min-width: $mobile) {
		width: 33%;
	}

	@media (min-width: $desktop) {
		width: 20%;
	}
}

.grid__gutter-sizer {
	width: 0px;
}

.page-load-status {
	display: none; /* hidden by default */
	padding-top: 20px;
	text-align: center;
	color: #777;
}


.loader-ellips {
	font-size: 20px; /* change size here */
	position: relative;
	width: 4em;
	height: 1em;
	margin: 10px auto;
}

.loader-ellips__dot {
	display: block;
	width: 1em;
	height: 1em;
	border-radius: 0.5em;
	background: #555; /* change color here */
	position: absolute;
	animation-duration: 0.5s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
}

.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
	left: 0;
}
.loader-ellips__dot:nth-child(3) { left: 1.5em; }
.loader-ellips__dot:nth-child(4) { left: 3em; }

@keyframes reveal {
	from { transform: scale(0.001); }
	to { transform: scale(1); }
}

@keyframes slide {
	to { transform: translateX(1.5em) }
}

.loader-ellips__dot:nth-child(1) {
	animation-name: reveal;
}

.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
	animation-name: slide;
}

.loader-ellips__dot:nth-child(4) {
	animation-name: reveal;
	animation-direction: reverse;
}

.default-section{
	.storyHeader__title{
		color: $t-color-primary;
	}
	.navbarCategories__item{
		a{
			color: $t-color-primary;
		}
		&.active{
			.navbarCategories__border{
				border-top-color: $t-color-primary;
			}
		}
	}

}

.cmp_newsList.tpl_newsListDefault {
	.slick-track {
		display: flex;
	}
	.stories-grid__item.slick-slide {
		height: auto;
	}
}

#download-manuals-loader{
	.loader-ellips__dot{
		background: white;
	}
	.page-load-status{
		display: block;
	}
}

 #download-manuals-no-result{
	 display: none;
 }