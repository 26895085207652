.bannersSlider__container{
	@media (max-width: $mobile) {
		max-width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}
}
.bannersSlider{
	max-width: $max-screen-size;
	margin: 0 auto;
	.slick-list{
		display: flex;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			min-width: 100%;
		}
		.slick-track{
			display: flex;
		}
	}
	.bannersSlider__singleSlider{
		height: 100%;
		align-items: baseline;
		margin-left:0px;
		margin-right:0px;
		@media (min-width: $tablet-L) {
			width:auto;
		}
		@media (min-width: $desktop) {
			width: 100%;
			margin-left: unset;
			margin-right: unset;
			padding: unset;
		}
		&.slick-slide{
			margin:0 7px;
			@media (min-width: $desktop) {
				margin: unset;
			}
		}
		.bannersSlider__sliderBlock{
			display: unset;
			@media (min-width: $desktop) {
				display: flex;
				height: 100%;
			}
		}
	}
	.bannersSlider__slider{
		height: 100%;
		align-items: baseline;
		margin-left:0px;
		margin-right:0px;
		width:380px;
		@media (min-width: $tablet-L) {
			width:580px;
		}
		@media (min-width: $desktop) {
			width: 100%;
			margin-left: unset;
			margin-right: unset;
			padding: unset;
		}
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			min-width: 100%;
			max-height: 600px;
		}
		
		
		&.slick-slide{
			margin:0 7px;
			@media (min-width: $desktop) {
				margin: 0;
			}
		}
		.bannersSlider__sliderBlock{
			display: unset;
			@media (min-width: $desktop) {
				display: flex;
				height: 100%;
			}
		}
	}
	$BoxDesktopHeight:100%;
	$BoxTablet-LHeight:323px;
	$BoxTablet-PHeight:212px;
	$BoxMobileHeight:167px;
	.sliders-gallery{
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			min-height: 592px;
		}
		.videoBox {
			height: $BoxMobileHeight;
			width: 100%;
			@media (min-width: $tablet-P) {
				height: $BoxTablet-PHeight;
			}
			@media (min-width: $tablet-L) {
				height: $BoxTablet-LHeight;
			}
			@media (min-width: $desktop) {
				min-height: 382px;
			}
			@media (min-width: $desktop-XL) {
				min-height: 592px;
			}
			.video {
				width: 100%;
				min-height: $BoxMobileHeight;
				object-fit: cover;
				@media (min-width: $tablet-P) {
					min-height: $BoxTablet-PHeight;
					width: 100%;
				}
				@media (min-width: $tablet-L) {
					height: $BoxTablet-LHeight;
				}
				@media (min-width: $desktop) {
					min-height: 382px;
					height: auto;
					max-width: none;
					width: 100%;
				}
				@media (min-width: $desktop-XL) {
					min-height: 592px;
				}
				.plyr__video-wrapper,
				.plyr__video-embed{
					min-height: inherit!important;
					object-fit: cover;
					@media (min-width: $tablet-P) {
						min-height: $BoxTablet-PHeight;
					}
					@media (min-width: $tablet-L) {
						height: $BoxTablet-LHeight;
					}
					@media (min-width: $desktop) {
						height: auto;
						width: auto;
						object-fit: none;
					}
				}
				video {
					width: 100%;
					min-height: $BoxMobileHeight;
					object-fit: cover;
					@media (min-width: $tablet-P) {
						min-height: $BoxTablet-PHeight;
						width: 100%;
					}
					@media (min-width: $tablet-L) {
						height: $BoxTablet-LHeight;
					}
					@media (min-width: $desktop) {
						height: auto;
						width: auto;
						object-fit: none;
					}
				}
			}
		}
		.slider__image {
			height: $BoxMobileHeight;
			@media (min-width: $tablet-P) {
				height: $BoxTablet-PHeight;
			}
			@media (min-width: $tablet-L) {
				height: $BoxTablet-LHeight;
			}
			@media (min-width: $desktop) {
				height: auto;
			}
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				img{
					position: absolute;
					top:50%;
					transform: translate(0,-50%);
				}
			}
		}
	}
	.sliders-texts{
		.slider{
			&__text-block{
				padding: 25px 10px 50px 10px;
				@media (min-width: $desktop) {
					padding: 25px 60px;
				}
				@media (min-width: $desktop-XL) {
					padding-left: 60px;
					padding-right: 60px;
				}
			}
			&__title{
				font-family: $font-secondary;
				font-size: 18px;
				font-weight: 500;
				letter-spacing: 0.72px;
				line-height: 26px;
				text-transform: uppercase;
				@media (min-width: $tablet-L) {
					height: auto;
					font-size: 32px;
					font-weight: 400;
					letter-spacing: 1.28px;
					line-height: 38px;
				}
			}
			&__introduction{
				font-family:$font-primary;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				display:none;
				@media (min-width:$mobile) {
					display:block;
				}
				@media (min-width: $tablet-L) {
					height:auto;
					min-height: 86px;
					font-weight: 300;
					line-height: 30px;
					letter-spacing: 0.64px;
				}
				@media (min-width: $desktop) {
					line-height: 20px;
				}
				@media (min-width: $desktop-XL) {
					line-height: 30px;
				}
			}
		}
	}
	&.slick-dotted.slick-slider {
		margin-bottom: 0px;
	}
	.slick-dots{
		width: auto;
		position: absolute;
		right: 37px;
		left:37px;
		@media (min-width: $tablet-L) {
			width: 33%;
			text-align: left;
			bottom: $dots--pb;
			padding-left:60px;
			padding-right:60px;
			right: 0;
			left: auto;
		}
		@media (min-width: $desktop) {
			bottom: 0px;
			padding-left:25px;
			padding-right:25px;
		}
		@media (min-width: $desktop-XL) {
			bottom: 60px;
			padding-left:60px;
			padding-right:60px;
		}
		li {
			width: 102px;
			margin: 0px;
			padding-right: 10px;
			@media (min-width: $tablet-L) {
				width: 50px;
				margin-left: 0px;
				padding-right: 10px;
			}
			button {
				border-bottom: 3px solid $grey;
				width: 100%;
				&:before {
					content: "";
				}
			}
			&.slick-active {
				button {
					border-color: $white;
				}
			}
		}
	}

}