@import "stores-locator-shared";

.stores-locator .stores-list {

  overflow: auto;

  &__content {
    height: auto;
    padding: 20px 0;
    background-color: rgba(0, 0, 0, 0);
    transition: height 1s;
    -moz-transition: height 1s;
    -webkit-transition: height 1s;
    -ms-overflow-style: none;
    @media (min-width: $mobile) {
      background-color: $black;
      position: initial;
      z-index: initial;
    }

    p {
      color: $white;
      font-family: 'Mulish', sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.32px;
      line-height: 34px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .pages-index {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;

      .slick-arrow {
        transform: none;
        position: inherit;
        top: 0;

        &.disabled {
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }

  .store-detail {
    background-color: $light-black;
    margin: 0 1rem;
    cursor: pointer;

    &:hover {
      background-color: #363636;
    }

    &.hidden {
      display: none;
    }

    > div {
      span {
        color: $dark-grey;
        font-family: 'Mulish', sans-serif;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.32px;
        line-height: 16px;
        margin: 0 0 5px 0;
      }

      p,
      a {
        color: $white;
        font-family: 'Mulish', sans-serif;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.64px;
        line-height: 30px;
        margin: 0 0 5px 0;
      }

      .action-link {
        display: initial;
        position: relative;
        align-items: center;
        color: $white;
        font-family: Raleway, sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.44px;
        line-height: 36px;
        width: 100%;

        &:before {
          content: "";
          display: inline-block;
          margin-right: 20px;
          line-height: 20px;
          border-top: 1px solid $white;
          width: 40px;
          vertical-align: middle;
          transition: all 1s ease-in-out;
        }

        &:hover {
          color: $white;
          text-decoration: none;

          &:before {
            margin-right: 30px;
            width: 50px;
            transition: all 1s ease-in-out;
          }
        }
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1rem;

      > h4 {
        color: $white;
        font-size: 1.125rem;
        line-height: 26px;
        letter-spacing: 0.72px;
        text-transform: uppercase;
      }

      > h6 {
        font-size: 0.75rem;
        line-height: 16px;
        letter-spacing: 1.68px;
      }
    }

    &__expand {
      width: 20%;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        border: none;
        background-color: transparent;
        background-image: url($plus-base64);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px auto;
        width: 24px;
        height: 24px;

        &.extended {
          background-image: url($minus-base64);
        }
      }
    }

    &__additional {
      max-height: 0;
      overflow: hidden;
      transition: max-height .3s;
      -moz-transition: max-height .3s;
      -webkit-transition: max-height .3s;

      &.extended {
        max-height: 500px;
      }
    }
  }
}
