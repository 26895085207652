$eforms-button-loader-size: 40px;

.eform-heading,
.eform-component {
    padding-top: 25px;
    padding-bottom: 25px;
    @media (min-width: $tablet-P) {
        padding-top: 75px;
        padding-bottom: 75px;
    }
    @media (min-width: $desktop) {
        margin-left: auto;
        margin-right: auto;
    }
    .title{
        font-family: $font-secondary;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.72px;
        line-height: 26px;
        margin-bottom: $text--pt;
        @media (min-width: $tablet-P) {
            font-size: 20px;
            letter-spacing: 1.28px;
            line-height: 38px;
        }
        @media (min-width: $desktop-XL) {
            font-size: 32px;
        }

    }

    &.white-section {
        h1, h2, h3, h4, h5, h6, p, span {
            color: $black;
        }
    }

    &.black-section {
        h1, h2, h3, h4, h5, h6, p, span {
            color: $white;
        }
    }
}

.eform-component {

    @media (min-width: $desktop) {
        max-width: 920px;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            max-width: none;
        }
    }

    > h4 {
        font-family: $font-secondary;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.72px;
        line-height: 26px;
        margin-bottom: 15px;
    }

    margin: 0 auto;

    .eform-component__block {
        margin-bottom: 50px;

        @media (min-width: $tablet-P) {
            margin-bottom: 150px;
        }

        @media (min-width: $desktop) {
            max-width: 920px;
            margin-left: auto;
            margin-right: auto;
        }

        > h4 {
            font-family: $font-secondary;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.72px;
            line-height: 26px;
            margin-bottom: 15px;

            @media (min-width: $tablet-P) {
                text-align: center;
                margin-bottom: 25px;
            }

            @media (min-width: $desktop) {
                font-family: $font-secondary;
                font-weight: 400;
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 50px;
            }
        }

        > h6 {
            font-family: $font-secondary;
            font-size: .8rem;

            @media (min-width: $tablet-P) {
                text-align: center;
            }

            @media (min-width: $desktop) {
                font-family: $font-secondary;
                font-size: 1rem;
                margin-bottom: 30px;
            }
        }

        .form-group {
            position: relative;
            margin-bottom: 25px;
            max-width: 100%;
            @media (min-width: $tablet-P) {
                margin-bottom: 30px;
            }

            @media (min-width: $desktop) {
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
            }

            > div {
                label {
                    font-family: $font-secondary;
                    font-size: 10px;
                    font-weight: 700;
                    letter-spacing: 2.88px;
                    line-height: 24px;
                    text-transform: uppercase;
                    margin-bottom: 11px;

                    @media (min-width: $tablet-P) {
                        font-size: 12px;
                    }

                    span {
                        position: absolute;
                    }
                }
            }

            input,
            button {
                font-family: $font-primary;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0.4px;
                line-height: 34px;

                @media (min-width: $tablet-P) {
                    font-size: 20px;
                }

                &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder { /* Edge, Internet Explorer */
                    color: $light-grey;
                }

                &:focus {
                    border-bottom: 1px solid;
                }

                &:hover {
                    border-bottom: 1px solid;
                    transition: all 0.3s ease-in;
                }
            }

            textarea {
                color: $light-grey;
                font-family: $font-primary;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.32px;
                line-height: 28px;
                min-height: 197px;

                &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder { /* Edge, Internet Explorer */
                    color: $light-grey;
                }

                &:focus {
                    border-bottom: 2px solid $white;
                }
            }

            &.checkboxgroup {
                label {
                    font-family: $font-primary;
                    font-size: 12px;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                    line-height: 28px;
                    text-transform: none;
                    color: $light-grey;

                    &.error {

                        &:before {
                            border-color: red !important;
                        }
                    }

                    @media (min-width: $tablet-P) {
                        font-size: 14px;
                    }

                    &:before {
                        border-radius: 0px;
                        border: 1px solid $light-grey;
                    }

                    span {
                        margin-left: 5px;
                    }
                }

                .custom-control-input:checked ~ .custom-control-label {
                    &:before {
                        color: $black;
                        background-color: $black;
                        border-color: $color-secondary;
                    }
                }
            }

            .form-control:focus {
                border: auto;
                outline: none;
                box-shadow: none;
            }

            .form-control-file {
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                opacity: 0;
                z-index: 2;

                & + .action-link {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    z-index: 3;
                    height: 24px;
                    text-transform: none;
                    top: 50px;
                    text-align: right;

                    &:hover {
                        cursor: pointer;
                    }

                }
            }

            .eforms-error {
                display: none;
                color: red;
            }

            &.error,
            &.errors{

                &.checkboxgroup {
                    label {
                        &:before {
                            border-color: red !important;
                        }
                    }
                }

                .form-control {
                    border-color: red !important;
                }

                .eforms-error {
                    display: block;
                }

                .eforms-hint {
                    display: none;
                }
            }
        }

        .eforms-pagetab {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid;
            height: 17px;
            margin: 0 30px 100px 30px;
            padding: 0;
            text-align: center;

            li {
                border-radius: 50px;
                border: 1px solid;
                font-size: 22px;
                width: 38px;
                height: fit-content;
                display: inline-block;
                text-align: center;
                top: -15px;
                background-color: $white;
                color: $black;

                &.selected {
                    background-color: $black;
                    color: $white;
                }

                &:first-child {
                    margin-left: 0;
                    float: left;
                }

                &:last-child {
                    margin-right: 0;
                    float: right;
                }
            }
        }

        .eforms-buttons {
            width: 100%;
            margin-top: 25px;
            display: table;

            > div {
                float: none;
            }

            @media (min-width: $tablet-P) {
                margin-top: 40px;
            }

            @media (min-width: $desktop) {
                > div {
                    float: right;
                }
            }

            .eforms-button {

                &.loading {

                    input, button {
                        font-size: 0;
                        line-height: 0;
                    }

                    .button-loader,
                    .button-loader:after {
                        border-radius: 50%;
                        width: $eforms-button-loader-size;
                        height: $eforms-button-loader-size;
                    }

                    .button-loader {
                        margin: auto;
                        font-size: 10px;
                        position: absolute;
                        top: calc(50% - #{$eforms-button-loader-size / 2});
                        left: calc(50% - #{$eforms-button-loader-size / 2});
                        text-indent: -9999em;
                        border-top: .5em solid rgba(255, 255, 255, 0.2);
                        border-right: .5em solid rgba(255, 255, 255, 0.2);
                        border-bottom: .5em solid rgba(255, 255, 255, 0.2);
                        border-left: .5em solid;
                        -webkit-transform: translateZ(0);
                        -ms-transform: translateZ(0);
                        transform: translateZ(0);
                        -webkit-animation: load8 1.1s infinite linear;
                        animation: load8 1.1s infinite linear;
                    }

                    @-webkit-keyframes load8 {
                        0% {
                            -webkit-transform: rotate(0deg);
                            transform: rotate(0deg);
                        }
                        100% {
                            -webkit-transform: rotate(360deg);
                            transform: rotate(360deg);
                        }
                    }
                    @keyframes load8 {
                        0% {
                            -webkit-transform: rotate(0deg);
                            transform: rotate(0deg);
                        }
                        100% {
                            -webkit-transform: rotate(360deg);
                            transform: rotate(360deg);
                        }
                    }
                }
            }

            button, input[type=button] {
                line-height: 24px;
                text-align: center;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                min-height: 45px;
                height: auto;
                padding: 10px 0;
                white-space: nowrap;
                background: #020202;
                color: #fff;
                font-size: 16px;
                min-width: 200px;
                font-family: Raleway,sans-serif;
                font-weight: 700;
                letter-spacing: 1.92px;
                text-transform: none;
                border: 0 none;

                @media (min-width: $tablet-P) {
                    //height: 90px;
                    font-size: 16px;
                    width: 100%;
                }

                &:hover {
                    color: $color-secondary;
                    border: 1px solid $color-secondary;
                }
            }
        }


        .select2.select2-container {
            height: calc(1.5em + .75rem + 2px);
            font-family: $font-primary;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0.4px;
            line-height: 34px;
            width: 100% !important;

            @media (min-width: $tablet-P) {
                font-size: 20px;
            }

            .select2-selection {

                &.select2-selection--single {
                    border: none;
                    border-radius: 0;
                    height: calc(1.5em + .75rem + 2px);
                    color: $light-grey;
                    font-family: $font-primary;
                    font-size: 18px;
                    font-weight: 400;
                    letter-spacing: 0.4px;
                    line-height: 34px;
                    background-color: transparent;

                    border-bottom: 1px solid $light-grey;

                    @media (min-width: $tablet-P) {
                        font-size: 20px;
                    }

                    &[aria-expanded="true"] {
                        border-bottom: none;
                    }

                    .select2-selection__rendered{
                        font-family: $font-primary;
                        font-size: 18px;
                        font-weight: 400;
                        letter-spacing: 0.4px;
                        line-height: calc(1.5em + .75rem + 2px);
                        @media (min-width: $tablet-P) {
                            font-size: 20px;

                        }

                    }

                    .select2-selection__arrow {
                        height: calc(1.5em + .75rem + 2px);
                        background-image: url('#{$images}/select-icon.png');
                        background-repeat: no-repeat;
                        background-size: 14px auto;
                        background-position: center center;

                        b {
                            display: none;
                        }
                    }

                }

            }

            &.select2-container--open {

                .select2-selection--single {

                    .select2-selection__arrow {
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

                }

            }

        }

        @media (min-width: $tablet-P) {
            .special-position {
                position: absolute;
                left: 0px;
                bottom: 0px;
            }
        }
    }

    .ui-slider-horizontal {
        height: 1px;
        border-radius: 15px;
        position: relative;
        margin-right: 0;
        margin-bottom: 20px;
        padding-right: 0;

        a.ui-slider-handle {
            top: -16px;
            border-radius: 15px;
            height: 32px;
            width: 32px;
            background: #dedede;
            color: #000;
            position: absolute;
            left: 0;
            font-weight: normal;
            margin-left: 0;
            text-align: center;

            &:hover {
                cursor: pointer;
            }

            &:after {
                display: block;
                content: "";
                height: 16px;
                width: 16px;
                position: absolute;
                background-size: contain;
                background-image: url('#{$images}/icons/arrow-right-double.png');
                background-repeat: no-repeat;
                background-position: center;
                top: 50%;
                left: 50%;
                transform: translate(-8px, -8px);
            }
        }
    }

    .antispam {

        .form-group {

            &.error
            &.errors {
                #notSlided > p {
                    color: red;
                }
            }

            @media (min-width: $tablet-L) {
                margin-left: auto;
                margin-top: 10px;
            }

            .ui-slider {
                @media (min-width: $tablet-L) {
                    margin: 0 auto 20px;
                }
            }

            #slided > p, #notSlided > p {
                text-align: center;
                font-family: 'Mulish', sans-serif;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: .28px;
                line-height: 28px;
                text-transform: none;
                color: #a0a0a0;
            }
        }
    }

    .white-section & {
        input,
        span[role="combobox"],
        span[role="textbox"],
        .selectize-dropdown,
        .select2-container--default .select2-results__option,
        .select2-results__option,
        .select2-results__option--highlighted[aria-selected] {
            color: $black;

            &:hover,
            &:focus {
                color: $black;
            }
        }

        input,
        span[role="combobox"],
        .checkboxgroup label:before,
        button,
        input[type=button],
        .selectize-input.full,
        .selectize-input.not-full,
        .selectize-input.input-active,
        .selectize-dropdown {
            background-color: $white;
        }

        .checkboxgroup .custom-control-input:checked ~ .custom-control-label:before {
            background-color: $black;
        }

        button,
        input[type=button] {
            color: $dark-grey;
            border: 1px solid $dark-grey;

            &:hover {
                color: $black;
                border: 1px solid $black;
            }
        }

        .eform-component .eform-component__block .form-group > div label,
        .eforms-pagetab li {
            border: 1px solid $black;
        }

        .eforms-button .button-loader {
            border-left: $black;
        }
    }

    .black-section &{
        input,
        button,
        span[role="combobox"],
        span[role="textbox"],
        textarea,
        .selectize-input,
        .selectize-input.full,
        .selectize-input.not-full,
        .selectize-input.input-active,
        .selectize-dropdown {
            color: $t-color-primary;

            &:hover,
            &:focus {
                color: $t-color-primary;
            }
        }

        input,
        button,
        span[role="combobox"],
        textarea,
        .selectize-input.full,
        .selectize-input.not-full,
        .selectize-input.input-active,
        .selectize-dropdown,
        .checkboxgroup .custom-control-input:checked ~ .custom-control-label:before {
            background-color: $black;
        }


        button,
        input[type=button],
        input[type=submit] {
            color: $light-grey !important;
            border: 1px solid $light-grey !important;

            &:hover {
                color: $white !important;
                border: 1px solid $white !important;
            }
        }
    }
    .eform-component__block{
        .login-error {
            display: none;
            .login-error__text {
                display: inherit;
                font-size: 1rem;
                font-family: $font-primary;
                font-weight: 300;
                letter-spacing: .32px;
                color: $red;
                padding: 10px 20px;
                border: 1px solid $red;
                border-radius: 5px;
            }

        }
        .eforms-error__enabled{
            .login-error {
                display: block;
            }

        }
    }
}

.white-section{
    .eform-component{
        .form-control{
            background-color:$white;
            color: $black;
        }
        .selectize-dropdown [data-selectable]{
            color: $black;
        }
        .select2-container--default
        .select2-selection--single
        .select2-selection__rendered {
            color: $black;
        }
        .eform-component__block .form-group textarea {
            color: $black;
        }
    }
}
.black-section{
    .eform-component{
        .form-control{
            background-color:$bg-color-primary;
            color: $t-color-primary;
        }
        .selectize-dropdown [data-selectable]{
            color: $white;
            &.active{
                color:$black;
            }
        }
        .selectize-input {
            color: $white;
        }

        .select2-container--default
        .select2-selection--single
        .select2-selection__rendered {
            color: $white;
        }
        .eform-component__block .form-group textarea {
            color: $white;
        }
    }
}

#ssc-form{
    .eform-component{
        .form-control{
            background-color:$white;
            color: $black;
            padding-left: 8px;
        }
        .selectize-dropdown [data-selectable]{
            color: $black;
            &.active{
                color:$black;
            }
        }
        .selectize-input {
            color: $black;
            padding: 9px 0;
            border-radius: 0;
            border-bottom: 0;
            background-color:$white;
        }
        .selectize-input input {
            height: 10px;
        }

        .select2-container--default,
        .select2-selection--single,
        .select2-results__option,
        .select2-results__option--highlighted,
        .select2-selection__rendered {
            background-color:$white;
            color: $black;
        }

        .eform-component__block .form-group textarea {
            color: $black;
        }

        .action-link{
            color: $black;
        }

        .action-link:before{
            border-top: 1px solid black;
        }
    }
}

.select2-container--default {
    margin-top: -1px;

    .select2-dropdown {
        border-radius: 2px;
        border-bottom: 3px solid $light-grey;

    }
}

.select2-container--default {
    .select2-results__option {
        background-color: $white!important;
        color: $black!important;
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: #f5f5f5!important;
        color: $black!important;
    }

    .select2-results__option[aria-selected=true] {
        background-color: #f5f5f5!important;
        color: $black!important;
    }
}


.eform-component .eform-component__block .form-group {
    max-width: 100%;
}

#categories-container .products-list__container_images {
    min-height: 0;
}

.ssc_navigation_item {
    padding: 10px;
}

.autocomplete-sku {
    padding-right: 15px;
    padding-left: 15px;
}

.eform-component .eform-component__block .form-group .form-control-file + .action-link {
    top: 50px;
    text-align: right;
}

.eforms-hint {
    color: #BBB;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
    color: $black;
    font-weight: bold;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
    background: $black;
    box-shadow: $light-grey 0 1px 3px 0 inset;
    color: $white;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
    color: #fff !important;
    background: $light-grey !important;
    box-shadow: none !important;
}

.modal.eforms-modal {
    z-index: 9999;

    .modal-content {
        background-color: $black;

        .modal-header {
            border-bottom: 0;
            padding: .5rem 1rem .5rem;

            .close {
                opacity: 1;
                color: $white;
                text-shadow: none;
            }
        }

        .modal-body {
            padding: 0 30px 1.5rem;

            @media (min-width: $tablet-L) {
                padding-bottom: 2.5rem;
            }

            .modal-cover {
                display: none;

                @media (min-width: $tablet-L) {
                    display: initial;
                }

                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &.image--square{
                    @media (min-width: $tablet-L) {
                        display: flex;
                        place-items: center;
                    }
                    >img{
                        max-width: 100%;
                        width: unset;
                        height: unset;
                        object-fit: unset;

                    }
                }
            }

            .modal-form {

                .container {
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            .container {
                @media (min-width: $desktop) {
                    padding-right: 120px;
                    padding-left: 120px;
                }
            }

            .eform-component,
            .eform-heading {
                @media (min-width: $tablet-P) {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            }

            .eform-component {

                .eform-component__block {
                    margin-bottom: 0;

                    > h4 {
                        text-align: start;
                    }

                    > h6 {
                        text-transform: none;
                        text-align: start;
                        font-weight: normal;

                        @media (min-width: $tablet-L) {
                            font-size: 14px;
                        }
                    }
                    .title{
                        font-family: $font-secondary;
                        font-size: 18px;
                        font-weight: 400;
                        letter-spacing: 0.72px;
                        line-height: 26px;
                        text-transform: uppercase;
                        margin-bottom: $text--pt;
                        @media (min-width: $tablet-P) {
                            font-size: 20px;
                            letter-spacing: 1.28px;
                            line-height: 38px;
                        }
                        @media (min-width: $desktop-XL) {
                            font-size: 32px;
                        }
                    }
                }
            }
        }
    }

    &.white {
        color: $black;

        .modal-content {
            background-color: $white;

            .modal-header {

                .close {
                    color: $black;
                    text-shadow: none;
                }
            }
        }

        .eform-bg {

            .form-group {

                &.checkboxgroup label:before {
                    border-radius: 0;
                    border: 1px solid #a0a0a0;
                    background-color: $white;
                }

                &.checkboxgroup .custom-control-input:checked ~ .custom-control-label:before {
                    border: 1px solid #a0a0a0;
                    background-color: $light-black;
                }

                input {
                    color: $black;
                    background-color: $white;
                    border: 1px solid $black;
                }

                > div label {
                    color: $black;
                }
            }

            a {
                color: $black;
                text-decoration: underline;
            }

            .eforms-buttons button,
            .eforms-buttons input[type=button] {
                background-color: $white;
                color: $black;
                border-color: $black;
            }
        }
    }
}
.promoPopUp{
    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 0px 0px;
        grid-template-areas:
            "title "
            "gallery"
            "textBlock";
        @media (min-width: $tablet-L) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            grid-template-areas:
            "title gallery"
            "textBlock gallery"
            "textBlock gallery";
        }
    }

    .title { grid-area: title; }
    .gallery { grid-area: gallery; }
    .textBlock { grid-area: textBlock; }
}

.eform-component {
    .eform-confirmation-msg {
        border: 1px solid white;
        padding: 12px;
        margin: 2rem 0;
    }
}

form[name="newsletter-popup"] {
    .eforms-buttons {
        #submitButton {
            button {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                min-height: 45px;
                height: auto;
                padding: 10px 0;
                white-space: nowrap;
                background: #020202;
                color: #fff;
                font-size: 16px;
                min-width: 200px;
                font-family: Raleway,sans-serif;
                font-weight: 700;
                letter-spacing: 1.92px;
                text-transform: none;
                border: 0 none;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
   .eforms-modal {
        .title {
            margin-bottom: 0 !important;
        }
        button {
            margin: 0 !important;
        }
        .eform-component {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
   }
    
}

@media (min-width: $tablet-P) {
    .eforms-modal {
        &.text-on-left {
            .modal-form {
                order: -1;
            }
        }
       
        &.text-in-center {
            .modal-form {
                margin: 0 auto;
            }
            .modal-cover {
                display: none !important;
            }
        }
    }
}

#nlModal {
    &.modal.eforms-modal .modal-content .modal-body .eform-component .eform-component__block {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
            margin-bottom: 0;
        }
        >h6 {
            margin-bottom: 0;
        }
        button {
            margin: 0 !important;
        }

        .eforms-button {
            .form-group {
                margin-bottom: 0;
            }
        }

        .eforms-buttons {
            margin-top: 0;
        }

    }

    .row {
        @media (min-width: 1200px)  {
            align-items: center;
        }

    }
}

.simple-course-form{
    .eform-component {
        .select2-container--default {
            .select2-selection--single {
                .select2-selection__rendered{
                    color: black;
                }
            }
        }
        .eform-component__block {
            .form-group{
                .form-control{
                    color: black;
                    &:focus{
                        border-top: 0 !important;
                        border-left: 0 !important;
                        border-right: 0 !important;
                        border-bottom: 1px solid #a0a0a0 !important;
                    }
                }
            }
        }
    }
}