/* Nuovo Footer Menu */
.footer__container{
  max-width: none;
}

@media print {
  .hidden-print {
    display: none !important;
  }

  .pie-di-pagina-print {
    text-align: center;
    width: 100%;
  }
}

#newFooterMenu{
  line-height: 1.8rem;
  padding-top:50px;
  padding-bottom: 50px;
  margin-bottom:0;
  font-weight: 400;
  >.row{
    column-gap: 5%
  }
  .logo__link{
    width: 160px;
  }
  .list-unstyled{
    font-family: 'Mulish', sans-serif;
    a{
      font-weight: unset;
      color: var(--color-secondary);
      &:hover{
        color: var(--color-secondary);
        text-decoration: none;
      }
    }
  }
  .section-title{
    color: #baa483;
    display: block;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom:15px;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-size: 1rem;
    letter-spacing: 0.8px;
  }
  .footer__company-information{
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0.800000011920929px;
  }
  .company-information-menu {
     margin-top:50px;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 160%;
    li {
      line-height: 2rem;
      a.store__link {
        font-weight: 300;

      }
    }
  }
  #reactPlaceholder_paymentMethods{
    .footerPartnership{
      .titolo{
        display: none;
      }
      ul.paymentMethodsIcons{
        padding-left: 0;
      }
    }

  }
  .right-footer-menu{
    a.store__link {
      font-weight: 300;
    }
  }

  letter-spacing: 0.8px;

  b{
    font-size: 14px;
  }
  .LinkNewFooter a {
    font-family: Raleway,sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-decoration: underline;
  }
  .footer__assistenza-autorizzata a {
    line-height: 1.4;
  }

  display: flex;
  flex-wrap: wrap;

  &>li {
    display: block;
    width: 25%;
    margin-bottom:15px;
    @media screen and (max-width: 1199px) {
      margin-bottom:0;
    }
    &>a{
      &>span{
        color: #baa483;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-weight: 500;
      }
    }
  }

  .hasSubMenu>.sideMenuLevel {
    display: block;
    .body>ul>li{
      padding: unset;
    }
  }
  @media screen and (max-width: 1200px){
    &>li{
      .accordion--header {
        position: relative;
      }
      .accordion--header[data-toggle="collapse"]:after {
        position: absolute;
        content: url("#{$images}/icons/arrow-down-gold.svg");
        font-weight: 900;
        float: right;
        color: #baa483;
        top: 13px;
        right: 0;
        transform: rotate(-180deg);
        transition: all 0.1s ease;
      }
      .accordion--header[data-toggle="collapse"].collapsed:after {
        top:13px;
        transform: rotate(0deg);
        transition: all 0.1s ease;
      }
    }

    .hasSubMenu{
      min-width: 100%;
      flex-direction: column;
      align-items: baseline;
    }
  }
  @media screen and (min-width: 1200px){
    .collapse {
      display: block;
      height: auto !important;
      visibility: visible;
    }
    .collapsing{
      position: relative;
      height: unset !important;
      overflow: hidden;
    }
  }


}

#newDisclaimerMenu{
  padding-bottom: 100px;

  .disclaimer-nav {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 230%;
    letter-spacing: 0.8px;
    color: #9B9B9B;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .selectYourCountry {
    @media screen and (max-width: 992px) {
      padding-bottom: 55px;
    }
  }
}