// "extend" products-detail

$dark: #000000;
$mediumDark: #484848;
$lowDark: #797979;
$grey: #808080;
$lightGrey: #E3E3E3;
$lighterGrey: #F4F4F4;
$lighter: #ffffff;

.container.new-pdp {
  padding-top: 0;
  @media (max-width: $tablet-P){
    padding-left: 15px;
    padding-right: 15px;
    
  }
  + .reference-tab-component {
    .container {
      @media (max-width: $tablet-P){
        margin: 0;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    .container-fluid {
      @media (max-width: $tablet-P){
        padding-left: 15px; 
        padding-right: 15px;
      }
      
    }
    .tab-component {
      @media (max-width: $tablet-P){
        padding: 0;
      }
      
    }
    .product-detail__title {
      font-size: 1.6rem;
    }
  }
}

#pdp_new {
  color: $dark;

  .main__gallery {
    padding: 40px 50px 20px 50px;
    @media (max-width: $tablet-P){
      background: $lighterGrey;
    }
    @media (min-width: $tablet-P){
      padding: 80px 45px 64px 75px;
      position: relative;
    }
  }

  .product-details__tags {
      position: absolute;
      left: 0;
      top: 20px;
      z-index: 1;
    @media (min-width: $tablet-P){
      margin-bottom: 5px;
      left: -22px;
    }
    @media (min-width: $tablet-P){
      left: 14px;
      top: 53px;
      display: flex;
    }
    @media (max-width: $tablet-P){
      display: flex;
      top: 4px;
    }
    .product-details__tag__item {
      background-color: $lightGrey;
      padding: 10px 20px;
      color: $grey;
      text-transform: uppercase;
      font-size: 0.73rem;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;

      @media (min-width: $tablet-P){
        margin-right: 15px;
      }
      @media (max-width: $tablet-P){
        margin-right: 2px;
        padding: 8px;
      }
    }
  }
  .product-details__slider {
    @media (min-width: $tablet-P){
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      position: relative;
    }
    @media (max-width: $tablet-P){
      opacity: 0;
      transition: opacity 1s, visibility 1s;
    }

    .slick-arrow {
      color: #000;

      &:active,
      &:hover,
      &:focus {
        color: #000;
      }
      i {
        color: #000;
      }
    }
    &.slick-initialized {
      @media (max-width: $tablet-P){
        opacity: 1;
      }
    }

    .slick-track {
      display: flex;
      align-items: center;
    }

    .new-slick-next,
      .new-slick-prev {
        position: absolute;
        border: 0;
        background: transparent;
        top: 50%;
      }
      .new-slick-next {
        right: -15px;
      }
      .new-slick-prev {
        left: -15px;
      }
      .slick-dots li button:before{
        width: 95%;
        content:"";
        border-bottom: 3px solid;
      }
    .product-details__slide {
      display: flex;
      align-items: center;
      @media (min-width: $tablet-P){
        width: 48%;
        margin: 15px 0;
        aspect-ratio: 1;
      }
      &:hover {
        cursor: pointer;
      }
      &.slide__video {
        width: 100%;
        
        video {
          max-width: 100%;
        }

      }
    }
    .product-details__slide.singleImgView{
      @media (min-width: $tablet-P){
        width: 80%;
      }
    }
    &.single-image {
      @media (min-width: $tablet-P){
        justify-content: center;
      }
      .product-details__slide {
        .product-details__image{
          @media (min-width: $tablet-P){
            padding-bottom: 50px;
          }
        }
        
      }
    }
  }
  .product-details__slider__wrap {
    
    @media (min-width: $tablet-P){
      position: relative;
      display: flex;
    }
    .product-details__slider__menu {
      @media (max-width: $tablet-P){
        display: flex;
        justify-content: space-between;
        width: calc(100% + 50px);
        margin-left: -25px;
        padding-top: 30px;
      }
      
      @media (min-width: $tablet-P){
        order: -1;
        position: absolute;
        bottom: 15px;
        left: -60px;
      }

      a {
        display: block;
  
        &.panoramaIcon,
        &.realityIcon {
          path {
            @media (max-width: $tablet-P){
              fill: $dark;
              width: 24px;
              height: 24px;
            }
          }
          
          &:hover{
            path {
              fill: $dark;
            }
          }
          span {
            font-size: 0.8rem;
            @media (min-width: $tablet-P){
              display: none;
            }
          }
        }
        &.zoomIcon {
          path {
            @media (max-width: $tablet-P){
              stroke: $dark;
              width: 24px;
              height: 24px;
            }
          }
          &:hover{
            path {
              stroke: $dark;
            }
          }
        }
      }
      &.fixed {
        @media (min-width: $tablet-P){
          //position: fixed;
          bottom:auto;
          left: auto;
          margin-left: -60px;
          margin-top: 15px;
        }
      }
      &.fixedBottom {
        @media (min-width: $tablet-P){
          order: -1;
          position: absolute;
          bottom: 35px;
        }
      }
    }
  }
#gallery-modal {
  .modal-fullscreen {
    max-width: none !important;
    max-height: 100vh !important;
    width: 100vw;
    height: 100vh;
  }
  button.close {
    position: absolute;
    right: 20px;
    opacity: 1;
    z-index: 10;
  }
  .modal-dialog {
    max-width: 1024px;
    max-height: 100vh; 
    height: auto; 

    .zoomIcon {
      position: absolute;
      z-index: 2;
      &.zoom-plus {
        pointer-events: none;
        @media (max-width: $tablet-L) and (orientation: portrait){
          left: 1.1rem;
        }
      }
    }
    .carousel-item {

      &.zoomable-image {
        cursor: pointer;
      }
      
      img.zoomable-img {
        max-height: 70vh;
        height: auto;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  .carousel-control-next-icon, 
  .carousel-control-prev-icon {
    background: none;
    height: auto;
    width: auto;
    display: none;

//     @media (min-width: $desktop) {
//       display: -ms-flexbox;
//       display: flex;
//     }
  }

  .carousel-control-prev-icon {
    

    &::after {
      content: "<";
      color: #000;
      font-size: 24px;
      font-family: monospace;
      background: #fff;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
  .carousel-control-next-icon {
      background: none;

      &::after {
        content: ">";
        color: #000;
        font-size: 24px;
        font-family: monospace;
        background: #fff;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  .carousel-indicators {
    position: relative;
    margin: 0;
    width: 100%;
    overflow: hidden;
    overflow-x: auto; 
    

    button {
      border: 0;
      margin: 2px 0; 
      width: 9%;
      opacity: 0.5;
      background: none;
      max-width: 100px;
      min-width: 65px;

      &.active {
        opacity: 1;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
    &::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar{
        width: 10px;
    }

    &::-webkit-scrollbar-thumb{
        background-color: #000000;
        border: 1px solid #555555;
    }

  }
}

  .main__product__info {
    display: flex;
    flex-direction: column;
    @media (min-width: $tablet-P){
      padding: 80px 0 0 23px;
      
    }

    .main__product__info-wrap {
      display: flex;
      flex-direction: column;
      .ecoPartecipation-table{
        table{
          tr{
            td{
              &:first-child{
                width: 65px;
              }
            }
          }
        }
      }
    }
    // ordinamento contenuti 
    .main__title {
      @media (min-width: $tablet-P){
        order: 1;
      }
    }
    .main__description {
      @media (min-width: $tablet-P){
        order: 2;
      }
    }
    .main__info__menu { 
      @media (min-width: $tablet-P){
        order: 3;
      }
    }
    .color__selection {
      @media (min-width: $tablet-P){
        order: 4;
      }
    }
    .ecoPartecipation-table {
      @media (min-width: $tablet-P){
        order: 5;
      }
    }
    .product-details__infos__wrap {
      line-height: 24px;
      @media (min-width: $tablet-P){
        order: 6;
      }
    }
    .free-field--under-price {
      @media (min-width: $tablet-P){ 
        order: 7;
      }
    }
    .product-details__infos__btn {
      @media (min-width: $tablet-P){ 
        order: 8;
      }
    }
    .product-label__code {
      order: -1;
      margin: 15px 0 0 0;
      @media (min-width: $tablet-P){
        order: 8;
        margin: 30px 0;
      }
      .code {
        font-weight: bold;
      }
    }
    .product-services__container {
      @media (min-width: $tablet-P){
        order: 9;
      }
    }
    .social__footer {
      @media (min-width: $tablet-P){
        order: 10;
      }
    }
  }
  .main__title {
   
    h1 {
      .family {
        font-size: 1.5rem;
        padding: 5px 0;
        font-weight: 400;
        @media (min-width: $tablet-P){
          font-size: 2.3rem;
          padding: 10px 0;
        }
      }

      & > span {
        display: block;
      }

      span {
        &.aesthetics,
        &.code,
        &.ean {
          color: $lowDark;
          font-size: 0.9rem;
          line-height: 1.2;
          font-weight: 300;
          @media (min-width: $tablet-P){
            font-size: 1.2rem;
          }
        }

        &.code {
          font-size: 0.9rem;
          padding: 4px 0;
        }
      }
    }
    .symbols {
      display: flex;
      flex-wrap: wrap;

      .symbol {
        margin-right: 10px;
        cursor: pointer;

        &.symbol-extended {
          width: 100%;
          font-size: 12px;
          line-height: normal;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          &:hover{
            text-decoration: underline;
          }
          
          .symbol__img {
            margin-right: 5px;
          }
          
        }
      }

      .symbol__img {
        width: 35px;
        height: 35px;
      }
    }
  }

  .main__description {
    margin-top: 20px;
    line-height: 1.3rem;

    @media (min-width: $tablet-P){
      // max-height: 125px;
      // overflow: hidden;
      // position: relative;
      // height: 125px;
    }

    li {
      line-height: 1.3rem;
      margin-bottom: 6px;
    }

    .hasModal-link {
      text-decoration: underline;
      color: #888;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }

    .read-more {
      display: none;

      @media (max-width: $tablet-P){
        margin-bottom: 35px;
      }

      @media (min-width: $tablet-P){
        // position: absolute;
        // left: 0;
        // bottom: -2px;
        // background: $lighter;
        // display: block;
        // width: 100%;
        text-align: left;
        padding: 6px 0;
        color: $mediumDark;
      }
    }
    .read-less,
    .read-more {
      color: #000;
    }

    &.close-details {
      @media (min-width: $tablet-P){
        max-height: none;
        overflow: initial;
        height: auto;
      }

      .read-more {
        display: none;
      }
    }
    ul {
      margin-top: 10px;
      padding-left: 17px;
      margin-bottom: 0;
    }
 
    button {
      border: 0;
      padding: 0;
      margin: 0;
      text-decoration: underline;
      background: none;

      @media (max-width: $tablet-P){
        display: none;
      }
    }

    .benefit-modal {
      .modal-dialog {
        max-width: 500px;
  
        @media (min-width: $tablet-P){
          width: 100%;
        }
        
        .modal-body {
          padding: 0 2rem 4rem 2rem;
          align-items: center;
          flex-wrap: wrap;
        }
  
        .modal-header {
          justify-content: flex-end;
          border-bottom: 0 none;
  
          button {
            text-decoration: none;
            display: block;
  
            span {
              color: $dark;
              font-size: 35px;
              font-weight: 100;
            }
          }
        }
        .media-content {
          width: 100%;
          img,
          video {
            width: 100%;
          }
        }     
      }
    }
  }
  .main__info__menu {
    display: flex;
    margin: 20px 0 15px 0;
    flex-wrap: wrap;

    @media (max-width: $tablet-P){
      justify-content: space-between;
    }

    a {
      text-decoration: underline;
      margin-right: 35px;
      font-weight: 700;

      @media (min-width: $tablet-P){
        margin: 0;
        margin-right: 25px;
      }
    }
  }

  .color__selection{
   
    .colors__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-items: center;
      gap:.85rem;

      a{
        text-decoration: none;
      }
      
      .form-check {
        padding: 0;
        padding-left: 0;
        padding-top: 0;
      }

      div {
        &.disabled-color::after {
          display: block;
          content: "×";
          color: #000;
          font-weight: 700;
          font-size: 26px;
          margin-top: -5px;
          margin-left: -1px;
          height: 30px;
          width: 30px;
          text-align: center;
        }
      }

      label {
        display: block;
        padding: 0;
        width: 35px;
        height: 35px;
        border: 1px solid #d8d7d7;
        border-radius: 50%;
        position: relative;
        &:hover{
          cursor: pointer;
        }
        &.disabled::after {
          content: "|";
          transform: rotate(45deg);
          display: block;
          position: absolute;
          top: 5px;
          left: 50%;
          color: #000;
          font-size: 40px;
          font-weight: 100;
        }
      
      }
      input {
        opacity: 0;
        position: absolute;
      }
    }
  }

  .product-details__infos_priceEnergy {
    display: flex;
    max-width: none;
    align-items: center;
    margin-top: 25px;
    flex: auto;

    .product-energyClass {
      margin-right: 20px;
    }

    .prices {
      line-height: 34px;
      display: flex;
      align-items: baseline;
    }

    .price {
      font-size: 1.3rem;
      font-weight: 600;

      @media (min-width: $tablet-P){
        font-size: 2.3rem;
      }
    
      &.price--strikethrough{
        font-size: 1rem;
        font-weight: 500;
        margin-left: 22px;

        @media (min-width: $tablet-P){
          font-size: 1.1rem;
          margin-left: 32px;
        }
      }
    }
    .price-eco {
      font-size: 1rem;
      font-weight: 400;
      line-height: 12px;

      @media (min-width: $tablet-P){
        font-size: 1.3rem;
      }

      &.price--strikethrough{
        font-size: 1rem;
        font-weight: 400;
        margin-left: 22px;

        @media (min-width: $tablet-P){
          margin-left: 32px;
        }
      }
    }
  }
  .product-details__infos.buttonRow {
    justify-content: space-between;
    margin-top: 5px;
    .btn {
      width: 100%;
      @media (min-width: $tablet-P){
        width: 50%;
      }
      
      .button-primary {
        font-weight: 500;
        display: flex;
        font-size: 0.8rem;
        justify-content: center;
        align-items: center;
        line-height: 0.9;
        margin-bottom: 5px;

        span {
          line-height: 1;
        }
      }
    }
    .product-link__buy.btn {
      width: 100%;

      .btn_addToCart {
        font-weight: 500;
        font-size: 1rem;

        @media (min-width: 550px) and (max-width: $tablet-L){
           width: 50%;
        }
      }
    }
    .isOutOfStock {
      width: 100%;
      padding: 10px 15px;

      //@media (min-width: $tablet-P){
      //  width: 50%;
      //}
    }

    .backInStock-wrap {
      width: 100%;
      align-items: center;
      display: flex;

      @media (min-width: $tablet-P){
        width: 50%;
      }
    }
  }
  .product-cta__compare__wish {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 25px 0;

    @media (max-width: $tablet-P){
      margin: 25px 15px;
    }

    .dealer-link a {
      color: $dark;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (min-width: $tablet-P){
        border-right: 1px solid $mediumDark;
      }
      svg {
        width: 26px;
        height: 30px;
      }
    }

    .product-comparison {
      //width: 49%;
      color: $dark;
      font-weight: 500;
      display: flex;
      align-items: center; 

      
      &--centered {
        justify-content: center!important;
      }

      label {
        cursor: pointer;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
 
      input { 
        opacity: 0;
        position: absolute;
      }

      img {
        width: 26px;
        height: 30px;
      }
    }
    .product-link__wishlist{
      //width: 49%;
      @media (min-width: $tablet-P){
        border-left: 1px solid $mediumDark;
      }
      
      .btn_addToWishlist {
        color: $dark;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          font-size: 26px;
          line-height: 30px;
        }
      }
    }
    .product-link__wishlist,
    .product-comparison,
    .dealer-link {
      flex-grow: 1;
      line-height: 1;
    }
    &.allThree {
      .btn_addToWishlist,
      .product-comparison label,
      .dealer-link a {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 5px;
        align-items: center;
        justify-content: flex-start;
        line-height: 1;
        svg{
            margin-bottom: 0.5rem;
        }
      }
      .product-comparison label img {
        margin-bottom: 0.5rem;
      }
      .btn_addToWishlist {
            .icon{
                margin-bottom: 0.5rem;
            }
      }

    }
    &.onlyOne,
    &.firstTwo,
    &.lastTwo {
      .product-link__wishlist,
      .product-comparison,
      .dealer-link {
        width: 49%;

        @media (max-width: $tablet-P){
          justify-content: flex-start;
          text-align: left;

          button {
            text-align: left;

            .icon {
              margin-right: 20px;
            }
          }
        }
      }

      .product-comparison label img {
          margin-right: 0.4rem;
      }
    }
  }
.more-action {
  padding-left: 15px; 
  width: 100%;
}

  .product-label__code {
    color: $mediumDark;
    font-size: 0.9rem;
    @media (max-width: $tablet-P){
      font-size: 1rem;
    }
  }
  .product-services__container {
    background-color: $lighterGrey;
    padding: 25px;
    margin: 35px 0;

    .product-services__title {
      font-weight: 700;
      margin-bottom: 10px;
    }
    .product-services__service {
      font-weight: 400;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        display: flex;
        align-items: center;
      }

      .tot {
        font-weight: 500;
        font-size: 0.9rem;
        white-space: nowrap;
        min-width: 20%;
        text-align: right;

        .line-through {
          text-decoration: line-through;
        }
      }
    }
  }

  .row.social__footer {
    margin: 35px 0;
  }

  .buy-fixed-bar {
    display: flex;
    //flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    width: 100%;
    //background-color: #f8f8f8;
    background-color: #fff;
    align-items: center;
    z-index: 2;
    left: 0;
    padding: 10px 20px;
    justify-content: space-between;

    &.hide {
      display: none;
    }

    .prices {
      //width: 100%;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      text-align: right;
      line-height: 1.2rem;
      align-items: flex-end;
      margin-right: 8px;

      .price {
        display: flex;
        font-size: 20px;
        white-space: nowrap;

        span[itemprop="priceCurrency"] {
          order: 2;
        }

        &.price--strikethrough {
          font-size: 16px;
        }
      }
      .price--discounted {
        order: 2;
      }
      .discount {
        font-size: .7rem;
      }
    }
    .btn-backInStock {
      display: flex;
      flex-direction: column;
      font-size: .8rem;
      justify-content: center;
      align-items: center;
      line-height: 1.5em;
      white-space: nowrap;
      background-color: #f8f8f8;
      padding: 3px 5px;
      max-width: 100%;
      overflow: hidden;

      span {
        font-weight: 400;
      }
      a {
        text-decoration: underline;
        font-weight: 400;
      }
    }
    .product-link__buy {
      //width: 70%;
      padding: 0;
      min-width: auto;
      width: 100%;
      max-width: 70%;
      
      button {
        min-width: auto;
        line-height: normal;
      }
    }
    .col.backInStock-wrap {
      flex-grow: unset;
    }
    .btn_backInStock,
    .btn_addToWishlist {
       .testo,
       .testo2{
        display: none;
       }
       .icon {
        font-size: 24px;
       }
    }
    .product-comparison {
      //width: 29%;
      text-align: center;
      color: transparent;
      height: 32px;
      overflow: hidden;
      order: 3;
      display: none;

      label {
        width: 32px;
      }

      input {
        opacity: 0;
        position: absolute;
      }
    }
    @media (min-width: $tablet-P){
      display: none;
    }
  }

  #reactPlaceholder_backInStockProductDetail {
    margin-top: 25px;
  }
}

.tab-content {
  .product-subdetail.isAccordion {
    border-bottom: 1px solid $lightGrey;
    margin: 40px 0 0px;

    ~ .product-subdetail.isAccordion{
      margin: 0;
    }

    .product-detail__subtitle {
      display: block;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      cursor: pointer;

      &::after {
        content: '';
        display: inline-block;
        rotate: 180deg;
        font-size: 32px;
        font-family: monospace;
        transition: transform .15s ease-in-out;
        background: url('#{$images}/icons/arrow-accordion.svg') center no-repeat;
        height: 20px;
        width: 20px;
      }
    }

    .detail-list__list {
      height: 0;
      overflow: hidden;
      max-height: 0;
      transform: max-height .5s ease-in-out;
    }

    &.open {
      .product-detail__subtitle {
        &::after {
          transform: rotate(180deg);
        }
      }
      .detail-list__list {
        height: auto;
        overflow: visible;
        max-height: 10000px;
        transition: all 0.3s ease-in-out;
      }
    }
    img[title] {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
      border-radius:2px;
      position: relative;
    }
     img[title] > * {
      user-select: none;
    }
    img[title]:hover > * {
      user-select: auto
    }
    img[title]:hover:after {
      position: absolute;
      top: 100%;
      right: -10%;
      content: attr(title);
      border: 1px solid rgba(0, 0, 0, 0.2);
      background-color: white;
      box-shadow: 1px 1px 3px;
      padding: 0.3em;
      z-index: 1;
    }
    .title-tooltip {
      position: absolute;
      font-size: .7rem;
      background: #fff;
      padding: 3px;
      border: 1px solid #000;
    }
  }

  .product-detail.isAccordion {
    border-bottom: 1px solid $lightGrey;
    margin: 40px 0 0px;

    ~ .product-detail.isAccordion{
      margin: 20px 0;
    }

    .product-detail__title {
      display: block;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;

      @media (max-width: $tablet-P){
        font-size: 1.3rem;
        text-transform: none;
      }

      &::after {
        content: '';
        display: inline-block;
        rotate: 180deg;
        font-size: 32px;
        font-family: monospace;
        transition: transform .15s ease-in-out;
        background: url('#{$images}/icons/arrow-accordion.svg') center no-repeat;
        height: 20px;
        width: 20px;
      }
    }

    .title-tooltip {
      position: absolute;
      font-size: .7rem;
      background: #fff;
      padding: 0 5px;
      border: 1px solid #000;
    }

    .product-detail__content {
      height: 0;
      overflow: hidden;
      max-height: 0;
      transform: max-height .5s ease-in-out;

      .icon-30 {
        width: 30px;
      }
    }

    &.open {
      .product-detail__title {
        &::after {
          transform: rotate(180deg);
        }
      }
      .product-detail__content {
        height: auto;
        overflow: visible;
        max-height: 10000px;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .detail__txt {
    .hasModal {
      //margin-left: 20px;
      color: #868686;

      &:hover {
        cursor: pointer;
      }
    } 
  }

  .product-detail .product-detail__content .details .detail {
    display: flex;
    align-items: baseline;
    margin: 5px;

    .detail__txt {
      font-size: 14px;
      display: flex;
      align-items: center;
    }

    .detail__label {
      padding-top: 0;
    }
  }
}

.tooltip-service .tooltip-inner {
  max-width: 500px;
  color: #000;
  background: #fff;
  font-size: 0.8rem;
  line-height: normal;

  p {
    font-size: 0.8rem;
    line-height: normal;
  }
} 
.tooltip-arrow{
  background: #fff;
} 

.popover {
  max-width: fit-content;
  border-radius: 0;
  @media (max-width: $tablet-P){
    background: #ededed;
  }
 
}

.popover-benefit {
  
  max-width: 300px;
 

  .popover-benefit-header {
    display: flex;
    justify-content: flex-end;

    .close {
      float: none;
    }
  }

  .popover-benefit-body {
    padding: 0 15px 15px;
  }
}

.popover-benefit.hasMedia {
  max-width: 500px;

  @media (max-width: $mobile){
    max-width: calc(100vw - 40px);
  }
  .media-content {
    max-width: 100%;

    video,
    img {
      max-width: 100%;
      width: 100%;
      margin-bottom: 15px;
    }
  }
 
}

#ar-modal {
  .qr-ar-wrap{
    @media (min-width: $tablet-L){
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .qr-wrap {
      padding: 20px;
      @media (min-width: $tablet-L){
        width: 40%;
      }

      .text-no-ar{
        img{
          height: 100px;
        }
      }

      #qrcode img {
        margin: 0 auto;
        max-width: 100%;
        padding: 20px;
        width: 220px; 
      }
    }

    .ar-wrap {
      @media (min-width: $tablet-L){
        width: 55%;
      }
      :not(:defined) > * {
        display: none;
      }
      
      model-viewer {
        background-color: #eee;
        overflow-x: hidden;
        width: 100%;
      }
      
      @keyframes circle {
        from { transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
        to   { transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
      }
      
      @keyframes elongate {
        from { transform: translateX(100px); }
        to   { transform: translateX(-100px); }
      }
      
      model-viewer > #ar-prompt {
        position: absolute;
        left: 50%;
        bottom: 175px;
        animation: elongate 2s infinite ease-in-out alternate;
        display: none;
      }
      
      model-viewer[ar-status="session-started"] > #ar-prompt {
        display: block;
      }
      
      model-viewer > #ar-prompt > img {
        animation: circle 4s linear infinite;
      }
      
      model-viewer > #ar-failure {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 175px;
        display: none;
      }
      
      model-viewer[ar-tracking="not-tracking"] > #ar-failure {
        display: block;
      }
    }
  }
  
}

.slider-test {
  margin-top: 15px;
  padding-bottom: 40px;
  width: 100%;
  .carousel-inner {
    width: 80%;
    margin: 0 auto;
    .carousel-item {
        cursor: pointer;
    }
  }
  img {
    max-width: 100%;
  }

  .carousel-indicators {
    position: relative;
    margin: 10px 0 30px;

    button {
      border: none;
      margin: 3px;
      opacity: 0.6;
      background: none;
      max-width: 100px;

      &.active {
        opacity: 1;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: auto;
    bottom: 81px;
    @media (min-width: $tablet-P) and (max-width: $tablet-L) {
      bottom: 97px;
    }
    @media (min-width: $tablet-L) and (max-width: $desktop) {
      bottom: 85px;
    }
    @media (min-width: $desktop) and (max-width: $desktop-XL) {
      bottom: 90px;
    }
    @media (min-width: $desktop-XL) {
      bottom: 110px;
    }
  }

  .carousel-control-prev {
    left: -10%;
    .carousel-control-prev-icon {
      background: none;

      &:after {
        content: "<";
        color: #000;
        font-size: 24px;
        font-family: monospace;
      }
    }
  }
  .carousel-control-next {
    right: -10%;
    .carousel-control-next-icon {
      background: none;

      &:after {
        content: ">";
        color: #000;
        font-size: 24px;
        font-family: monospace;
      }
    }
  }
}

.cmp_BundleBox img{ max-width:400px; }
.cmp_BundleBox .btn{ background-color: #000; color: #FFF!important; }
.cmp_BundleBox .text p{ line-height:28px; }

@media (max-width: 768px) {
  .cmp_BundleBox .row{
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
  }
  .cmp_BundleBox h4{
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.cookiefirst-root.moveTop > span,
.footer_backToTop.moveTop,
.acsb-trigger.acsb-trigger-position-y-bottom.acsb-mobile.moveTop {
  bottom: 70px !important;
}
access-widget-ui.moveTop{
  &::part(acsb-trigger){
    bottom:70px;
  }
}


#reactPlaceholder_relatedProducts .title {
    font-weight: 500;
    font-family: raleway,sans-serif;
    line-height: 1.3;
//     text-transform: uppercase;

    @media (max-width: 768px) {
      padding: 0 15px;
    }
}

.product-details__infos__vat {
  margin-top: 20px;
  .payu-mini-installments-widget {
    background-image: url('#{$images}/icons/paymentMethods/payu0.svg');
    background-repeat: no-repeat;  
    background-repeat: no-repeat;
    display: flex;
    padding-left: 105px;
    background-size: 100px;
    background-position-x: -6px;
    background-position-y: center;

    a {
      color: #888 !important;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

