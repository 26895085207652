

@mixin recipeTag {
    background: #968870;
    color: $white;
    display: inline-block;
    font-weight: 400;
}


@import "cooking-details";

//RECIPES LIST + stories-grid.scss
.cooking-list {

    .recommend-title {

        margin: 25px 0;

        @media (min-width: $desktop) {
            padding-top:130px;
        }
    }

    .title{
        font-size: 32px;
        font-weight: 400;
        font-family: 'Raleway' ,sans-serif;
        @media (max-width: 767.9px) {
            margin: 0 37px;
        }
    }

    .stories-grid {
        margin-top: 0;
        padding-top: 50px;
    }

    .storyItem__textBlock {
        background: #f2f2f2;
        text-align: left;
        padding: 1rem 2rem 2rem 2rem;

        .storyItem__title{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-transform: none;
            font-weight: 400;
            @media (min-width: $desktop) and (max-width: $desktop-XL) {
                height: 42px;
                line-height: 14px;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            height: 48px;
            overflow: hidden;
            text-align: left;
            list-style: none;
            line-height: normal;

            display: flex;
            align-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;

            > :last-child {
                margin-top: auto;
            }
        }

        li {
            @include recipeTag;
            font-size: 0.8rem;
            margin: 2px 2px;
            white-space: nowrap;
            font-weight: 400;
            padding: 2px 8px;
        }
    }

    .storyItem__title {
        position: relative;
    }
}

.recipe-faceted-navigation {

    .title{
        font-size: 32px;
        font-weight: 400;
        font-family: 'Raleway' ,sans-serif;
    }

    .default-section { //fix spazio bianco quando nessun risultato
        overflow: hidden;
    }

    .navigation-title {

        margin: 25px 0;

        @media (min-width: $desktop) {
            padding-top:130px;
        }
    }

    .select2-container {
        width: 100% !important;

        .select2-selection__arrow {
            background-image: url('#{$images}/select-icon-cooking.png') !important;
            b {
                display: none;
            }
        }
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered{
        color: #020202;
        padding-left: 15px;
        padding-right: 35px;
    }
}

.faceted-navigation__filters {
    padding-bottom: 25px;

    //> div {
    //    padding: 0 15px;
    //    width: 100%;
    //
    //    @media (min-width: $tablet-P) {
    //        width: 50%;
    //    }
    //
    //    @media (min-width: $desktop) {
    //        width: calc(100%/3);
    //    }
    //}



}

.faceted-results {
    .selectedtags__link,
    .searchtags__link {
        @include recipeTag;
        line-height: normal;
        margin-right: 20px;
        padding: 2px 8px;
        font-size: .8rem;

        &:hover{
            color: white;
            text-decoration: none;
        }

        svg{
            width: 8px;
            height: 8px;
        }
    }
}

.cooking-select-dropdown{
    padding-right: 1rem;
    padding-bottom: 1rem;
    .select2-container--default {
        .select2-selection--single {
            border-radius: 0;
            border: 1.5px solid black !important;
            padding-top: 6px;
            height: 45px;
            .select2-selection__arrow {
                height: 45px;
                background-image: url("../images/select-icon.png");
                background-repeat: no-repeat;
                background-size: 14px auto;
                background-position: 50%;
                right: 10px;
                b{
                    display: none;
                }
            }
        }
    }
}

.centered-filter{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .title{
        font-size: 40px;
    }
    .suggestion-box{
        @media (max-width: $tablet-P) {
            display: flex;
            justify-content: center;
        }   
    }
    .advanced-filter-input{
        border: 0;
        padding: 5px 15px;
        background-image: url('#{$images}/search-icon--black.svg');
        background-position: right 10px center;
        background-repeat: no-repeat;
        width: 50%;
        @media (max-width: $tablet-P) {
            width: 75%;
        }
    }

    .btn-advanced-filter{
        background: black;
        color: white;
        padding: 10px 25px;
        border-radius: 0;
        &:hover{
            color: white;
        }
    }
}

.quick-info-container{
    display: flex;
    height: 35px;
    padding-bottom: .5rem;

    .quick-info{
        text-transform: uppercase;
        font-weight: 400;
        font-size: 10px;
        padding-top: 3px;
        padding-left: 0.5rem;
    }
}

//add here because is not inside of recipi-faceted-navigation
.select2-container--default {
    .select2-dropdown {
        .select2-search {
            .select2-search__field {
                outline: none !important;
                border: 1px solid #020202 !important;
                background-image: url('#{$images}/search-icon-cooking.png');
                background-position: right 10px center;
                background-repeat: no-repeat;
                padding-right: 30px;
            }
        }
        .select2-results {
            .select2-results__options {
                /* Modifica l'aspetto della barra di scorrimento */
                &::-webkit-scrollbar {
                    width: 4px; /* Imposta la larghezza della barra di scorrimento */
                }

                &::-webkit-scrollbar-thumb {
                    background: #020202; /* Colore della "maniglia" della barra di scorrimento */
                    border-radius: 10px; /* Aggiungi angoli arrotondati */
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: #020202; /* Cambia colore al passaggio del mouse */
                }

                &::-webkit-scrollbar-track {
                    background: #E3E3E3; /* Colore del tracciato della barra */
                    border-radius: 10px;
                }

            }
        }
    }
}