$font-primary: 'Mulish', sans-serif;
$font-secondary: 'Raleway', sans-serif;

$white: #ffffff;
$black: #020202;
$light-black: #161616;
$grey: #b4b4b4;
$lighter-grey: #363636;
$light-grey: #a0a0a0;
$dark-grey: #797979;
$dusty-grey: #f8f8f8;
$red: #B10011;
$light-red: #ff0000;
$dark-red: #640000;
$dark-gold: #968870;
$sustainability: #f0f0e6;
$sustainability-dark: #8c947a;
$t-sustainability: #979f8c;


$product-grey: #797979;
$product-light-grey: #e3e3e3;
$product-label: $dusty-grey;

$color-primary: $black;
$color-secondary: $white;
$color-secondary--disabled: $grey;
$color-dark:$black;
$color-tertiary: $light-grey;
$color-sustainability: $sustainability;
$color-sustainability-dark: $sustainability-dark;
$color-whiteSmoke:#F4F4F4;

/* Css variables*/
:root {
    --color-primary: #{$color-primary};
    --color-secondary: #{$color-secondary};
    --color-alert: #{$red};
    --color-alert-light: #{$light-red};
    --color-alert-dark: #{$dark-red};
    --color-light-gray: #{$light-grey};
    --color-porcelain: #f2f2f2;
    --color-success: #088500;
    --color-sustainability-dark: #{$sustainability-dark};
    --bg-color-sustainability: #{$sustainability};
    --t-color-sustainability: #{$t-sustainability};
    --color-gold: #baa483;
    --color-attention: rgba(160, 160, 160, 0.6);
    --color-whiteSmoke:#{$color-whiteSmoke};
}

/*Background color*/
$bg-color-primary: $color-primary;
$bg-color-secondary: $color-secondary;
$bg-color-dark: $black;
$bg-color-sustainability:$color-sustainability;


/*Text color*/
$t-color-primary: $white;
$t-color-secondary: $black;
$t-color-alternative: $color-secondary;
$t-color-sustainability: $t-sustainability;

/*Elements color*/
$el-color-primary:$black;
$btn-color-primary:transparent;
$border-color-primary:$grey;

$color-primary--menu: $color-primary;
$color-secondary--menu: $color-secondary;

$breadcrumbs: #b6b6b6;

/*Elements size*/
$el-max-width:960px;

$text--pt:25px;
$text--px:25px;
$text--pb:50px;
$space--xs:7px;
$space--sm:15px;
$space--md:25px;
$space--lg:50px;

$text__ul--px:80px;

$text__productCategory--pt:10px;

/* Icons */
$icon--search:'search-icon.svg';


/* Typography */
$title-fontSize: (xs: 16px, sm: 20px, md: 32px, lg: 40px);

$title-mobile-fontSize: (xs: 16px, sm: 18px, md: 26px, lg: 32px);

$subTitle-fontSize: (xs: 20px, sm: 26px, md: 32px, lg: 40px);

$text-fontSize: (sm: 12px, md: 16px, lg: 20px);

$subText-fontSize: (sm: 10px, md: 12px, lg: 14px);

/* Text */

$textBlock-width:60%;

/* Padding */
$paddingY: (0: 0px, 1: 15px, 2: 25px, 3: 50px);

$pb: (0: 0px, 1: 15px, 2: 25px, 3: 50px);

/* Letter spacing */
$letterSpacing:(md:0.3px,lg:1.3px);

/* Header */

//Burger menu
$burgerMenu: (
    offset-top-m: 60px,
    offset-top-d: 130px,
);

//Burger Menu 150%
$w-150: (
    offset-top: 120px,
);

//header_layers
   
$header-layer-padding-vertical: 30px;
$header-layer-padding-vertical-bottom: 22px; 
$header-layer-padding-horizontal: 15px;



/* Gallery */
$dots--pb:15px;

/* Buttons */
$minWidth:200px;
$pdpMinWidth:250px;