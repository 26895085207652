.burgerMenu {
  background: $color-primary--menu;
  border: none;
  
  &__firstLevel {
    width: 200px;
  }

  .menu-link, .menu-subLink
  .head {
    color: $t-color-primary;
  }

  .menu-link, .menu-subLink {
    .footer__social-icon__img {
      margin-top: -2px;
    }
  }
  

  .menu-link {
    font-family: $font-secondary;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.82px;
    line-height: 36px;
    text-transform: uppercase;
  }

  .menu-subLink {
    font-family: $font-secondary;
    font-size: 15px;
    letter-spacing: 1.82px;
    line-height: 36px;
  }

  .head {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 300;
    line-height: 38px;
  }
  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: $t-color-primary;
  }
} 

.menu-wrapper .hamburger-menu {
  position: relative;
  cursor: pointer;

  @media (min-width: 1200px) and (max-width: 1367px){
    zoom: 0.7;
    top: 7px;
  }

  &:before{
    content: '';
    display: block;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
  }
}

.menu-wrapper .hamburger-menu span {
  opacity: 1;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  border-radius: 10px;
  color: black;
  background-color: $color-secondary--menu;
  position: absolute;
  transform: rotate(0deg);
  transition: .4s ease-in-out;
}

.menu-wrapper .hamburger-menu span:nth-child(1) {
  top: 10px;
}

.menu-wrapper .hamburger-menu span:nth-child(2) {
  top: 20px;
}

.menu-wrapper.open .hamburger-menu span:nth-child(1) {
  transform: translateY(5px) rotate(135deg);
}

.menu-wrapper.open .hamburger-menu span:nth-child(2) {
  transform: translateY(-5px) rotate(-135deg);
}


.menu-container .menu-list .menu-submenu {
  padding-left:0;
  // .nav__item a{
  //   color: $t-color-primary;
  // }
}


.menu-container .menu-list {
  padding-left:20px; 
}

.menu-container .menu-list li.accordion-toggle,
.menu-container .menu-list li.toggle,
.menu-container .menu-list .menu-login {
  font-size: 16px;
  @media (min-width: $desktop){
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.menu-container .menu-list li:first-of-type {
  border-top: 0;
}

.accordion-toggle, .accordion-content {
  cursor: pointer;
  font-size: 12px;
  position: relative;
  letter-spacing: 1px;
}

.accordion-content {
  display: none;
}

.accordion-toggle .menu-link:before, .accordion-toggle .menu-link:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 30px;
  width: 15px;
  height: 2px;
  margin-top: -1px;
  background-color: $color-secondary--menu;
  transform-origin: 50% 50%;
  transition: all 0.3s ease-out;
}

.accordion-toggle .menu-link:before {
  transform: rotate(-90deg);
  opacity: 1;
  z-index: 2;
}

.accordion-toggle.active-tab {
  transition: all 0.3s ease;

  a {
    color: $white;
  }
}

.accordion-toggle .menu-link.active:before {
  transform: rotate(0deg);
  background: $color-secondary--menu;;
}

.accordion-toggle .menu-link.active:after {
  transform: rotate(180deg);
  background: $color-secondary--menu;;
  opacity: 0;
}
