@import "products-filters";

.products-list-page {
  .products-list-page__content {
    color:$black;
    margin-top: 80px;
    margin-bottom: 100px;

    .page-promotion & {
      margin-top: 0;
    }

    @media (max-width: $mobile) {
      margin-top: 45px;
    }
  }
  &.cmp_productManager {
    .cmp_heroBanner.tpl_textFocus {
      .textBlock {
        .title {
          font-size: 2rem;
          @media (min-width: $desktop) {
            font-size: 40px;
          }
        }
      }
    }
  }
  .btn_backInStock {
    line-height: normal;
  }
}


.products-list {
  justify-content: flex-start;

  .label-clickable {
    &:hover {
      text-decoration: underline !important;
      cursor: pointer;

      .label{
        text-decoration: underline;
      }
    }
  }

  .product-preview.noEcommerce {
    grid-template-rows: auto 1fr auto;
    @media (min-width: $tablet-P) {
      -ms-grid-rows: 70px 1fr auto;
      grid-template-rows: 70px 1fr auto;
    }
  }

  .product-preview {
    padding: 0 15px 27px;
    width: 350px;
    color: $product-grey;
    margin-bottom: 20px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 5fr auto 100px auto;
    grid-template-rows: auto 1fr auto 100px auto;
    @media (min-width: $tablet-P) {
      -ms-grid-rows: 70px 1fr auto 100px 30px 50px 50px;
      grid-template-rows: 70px 1fr auto 100px 30px 50px 50px;
    }


    grid-template-areas:
    "product-labels" // 60
    "product-content" //1fr 
    "product-footer" //auto
    "product-links"//auto
    "product-messages"//30
    "product-wishlist";//50

    .product-labels { -ms-grid-row: 1; -ms-grid-column: 1; grid-area: product-labels; }
    .product-content { -ms-grid-row: 2; -ms-grid-column: 1; grid-area: product-content; } 
    .product-footer { -ms-grid-row: 3; -ms-grid-column: 1; grid-area: product-footer; }
    .product-links { -ms-grid-row: 4; -ms-grid-column: 1; grid-area: product-links; }

    .product-content{
      a{
        &:hover{
          text-decoration: none;
        }
      }
    }

    &:hover {
      background-color: $product-label;
    }

    &.banner-for-listing:hover {
      background-color: transparent;
    }

    .product-preview__top {
      @media (min-width: $mobile) {
        min-height: 35px;
      }

      .label {
        display: inline-block;
        color: $product-grey;
        background-color: $product-label;
        padding: 8px 10px;
        line-height: normal;
        font: {
          family: $font-primary;
          size: 12px;
          weight: 600;
        }
        letter-spacing: 1.68px;
        text-transform: uppercase;
      }
    }

    .product-preview__gallery {
      margin-top: 10px;
      padding: 0;
      background-color: $white;
      min-height: 330px;
      max-height: 500px;
      overflow: hidden;
      display: flex;
      @media (min-width: $tablet-P) {
        padding: 25px;
      }
      @media (min-width: $desktop){
        min-height: 200px;
      }
      @media (min-width: $desktop-XL) {
          min-height: 330px;
      }
      img {
        margin: auto;
      }
    }

    .product-preview__banner {
      padding: 0;
      background-color: $white;
      min-height: 300px;
      max-height: 500px;
      overflow: hidden;
      display: flex;
      @media (min-width: $desktop) {
        min-height: 200px;
      }
      @media(min-width: $desktop-XL){
        min-height: 300px;
      }
      img {
        margin: auto;
      }
    }

    .product-preview__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 65px;
      flex-wrap: wrap; 
      //se il name e il price non ci stanno sulla stessa riga
      //vanno su due
      > * {
        flex: 1 1 50%; 
      }

      .name {
        color: $light-black;
        font: {
          family: $font-primary;
          size: 11px;
          weight: 400;
        }
        letter-spacing: 0.96px;
        line-height: normal;
        text-transform: uppercase;
        @media screen and (min-width:$tablet-L) and (max-width:$desktop){
          font-size: .7rem;
        }
        @media (min-width: $desktop) {
          font-size: 9px;
        }
        @media(min-width: $desktop-XL){
          font-size: 12px;
        }
      }
      .prices{
        .price{
          @media screen and (min-width:$tablet-L) and (max-width:$desktop){
            font-size: 1.2rem;
          }
        }
        .price--strikethrough{
          @media screen and (min-width:$tablet-L) and (max-width:$desktop){
            font-size: 0.8rem;
          }
        }
      }

    }

    .product-preview__description {
      color: $light-black;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.72px;
      line-height: 20px;
      min-height: initial;
      @media (min-width: $tablet-P) {
        min-height: 75px;
      }
      @media (min-width: $desktop) {
        font-size: 10px;
      }
      @media(min-width: $desktop-XL){
        font-size: 12px;
      }
    }

    .product-preview__description__giftcard {
      color: $light-black;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.72px;
      line-height: 20px;
      min-height: 40px;
      @media (min-width: $desktop) and (max-width: $desktop-XL) {
        font-size: 10px;
        min-height: 45px;
        line-height: 15px;
      }
    }

    .energy_label{
        min-height: 35px;
        font-size: 10px;
        @media (min-width: $desktop) {
            font-size: 9px;
        }
        @media(min-width: $desktop-XL){
            font-size: 12px;
        }
    }

    .product-preview__free-field{
      color: $light-black;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.72px;
      line-height: 20px;
      grid-column: span 2;
      @media (min-width: $mobile) {
        min-height: 30px;
      }
      
      @media (min-width: $desktop) {
        font-size: 10px;
      }
      @media(min-width: $desktop-XL){
        font-size: 12px;
      }
    }

    .product-footer {
      margin-bottom: .5rem;
      font-size: 12px;
      @media (min-width: $desktop) {
          font-size: 10px;
      }
      @media(min-width: $desktop-XL){
          font-size: 12px;
      }

      .product-energyClass {
        flex-basis: 100%;
      }
      .awardIcons{
        display: flex;
        gap: 2px;
        min-height: 36px;
        .symbols,
        .awards{
          span{
            margin-left: 2px;
            display: inline-block;
            img{
              width: 35px;
              height: 35px;
              cursor: pointer;
            }
          }
        }
      }
 

      .awards { //sempre a destra anche quando manca il product comparator
        margin-left : auto;

        span {
          display: inline-block;
          &:not(:first-of-type) {
            margin-left: 5px;
          }
        }
      }


    }


    .product-links{
      margin-right: -1px;
      margin-left: -1px;

      .product-messages {
        width: 100%;
        line-height: 18px;
      }

        .btn{
            width:100%;

            .button-primary{
                display:inline-flex;
                justify-content:center;
                align-items: center;
                min-height:45px;
                height:auto;
                padding:10px 0;
                white-space: nowrap;
            }

        }
        .product-link__buy,
        .product-link__more{
            padding: .375rem 0;
        }
      .product-link__buy{
        @media screen and(min-width: $tablet-P){
          //padding-bottom: 35px;
        }
      }
    }
    .free-field{
      color: $color-primary;
      &:empty{
        @media (max-width: $mobile) {
          display: none;

        }
      }
    }
  }
}

//bundle listing

.category-bundles {
  .products-list {
      .col-lg-4, .col-xl-3 {
          display: flex;
          flex-direction: column;
          justify-content: space-between; 
      }
      .product-content {
          flex-grow: 1;

          .product-preview__gallery {
            span.float-right {
              position: absolute;
              right: 15px;
              top: 0;
            }
          }
      }
  }
}

#bundle-list-tabs{
  .nav-item{
    .nav-link{
      padding-left: unset;
    }
  }
}

/* Banner for listing(colored banner for bundles) */
.banner-for-listing {
  grid-template-rows: 60px 1fr auto auto 30px 50px 50px !important;
  .banner-for-listing__title {
    font-size: 1.5rem;
    line-height: 26px;
    padding: 20px;
    
    @include font(Mulish);
  }
  .banner-for-listing__description {
    padding: 10px 25px;
    font-size: 16px;
    @media (max-width: $desktop) {
      line-height: 16px;
    }
    line-height: 20px;
    @include font(raleway);
  }
  .product-link__buy {
    max-width: 90%;
    margin: 0 auto;

    button {
      margin-bottom: 41px;
      width: 100%;
      min-width: auto;
    }
  }
  a {
    text-decoration: none!important;
    font-weight: 300!important;
  }
  .product-preview .product-preview__gallery {
    align-items: center;
  }
}
.product-preview.banner-for-listing {
  div[class^='banner-for-listing'] {
    color: var(--color-primary);
  }
}
.product-preview.banner-for-listing.text-black {
  div[class^='banner-for-listing'] {
    color: var(--color-primary);
  }
}
.product-preview.banner-for-listing.text-white {
  div[class^='banner-for-listing'] {
    color: var(--color-secondary);
  }
}
.product-preview {
  .product-links {
    .product-link__buy {
      padding: 1.375rem 0;
    }
    .product-link__more {
      padding: 1.375rem 0;
    }
  }
}

.promotion-preview{
    .strikethrough{
        text-decoration: line-through;
        font-size: .8rem;
        font-weight: 400;
    }

    .new-price{
        font-weight: 700;
        font-size: 1.5rem;
    }
}

.product-comparison {
  display: -webkit-box;
}

.bundle-information {
  min-height: 56px;
  @media (max-width: $tablet-P) {
    min-height: 76px;
  }
}