//component
.cmp_bannerSlider {
	.reverseBlocks,
	.sortBlocks{
		.container-text{
			@media (max-width: 767.9px) {
				padding-left:35px;
				padding-right:35px;
			}
		}
	}
	.container {
		.reverseBlocks {
			.container-text {
				@media (min-width: $desktop) {
					padding-left: 0px;
					padding-right: 15px;
				}
			}
		}

		.sortBlocks {
			.container-text {
				@media (min-width: $desktop) {
					padding-left: 15px;
					padding-right: 0;
				}
			}
		}
	}

	//special
	.container,
	.container-fluid{
		max-width: $max-screen-size;
		.reverseBlocks,
		.sortBlocks{
			.textBlock {
				@media (min-width: $desktop) {
					display: flex;
				}
			}
			.gallery{
				.slideShow{
				}
				.video,
				video,
				.image{
					width: 100%;
				}
				.image{
					a,
					picture{
						min-width: 100%;
					}
				}
			}

		}

		.reverseBlocks{
			.textBlock {
				@media (min-width: $desktop) {
					justify-content: flex-start;
				}
			}
			.gallery{
				@media (min-width: $desktop) {
					display: flex;
					justify-content: flex-end;
				}
				.slideShow,
				.video{

				}
			}
		}
		.sortBlocks {
			.textBlock {
				@media (min-width: $desktop) {
					justify-content: flex-end;
				}
			}
			.gallery{
				@media (min-width: $desktop) {
					display: flex;
					justify-content: flex-start;
				}
				.slideShow,
				.video{

				}
			}
		}
	}


	//visualbox con container e con bgInterno
	&.bgInt-default,
	&.bgInt-alternative{
		.container {
			.textBlock {
				@media (min-width: $desktop) {
					justify-content: center;
				}
			}
		}
	}
}

//templates
.cmp_bannerSlider{
	//template
	&.tpl_sliderDefault{
		align-items: center;
		.row {
			align-items: center;
			justify-content: space-between;
			.textBlock{
				display: flex;
				align-items: center;
				.container-text{
					max-width: 100%;
					width: 100%;
					@media(min-width: $desktop){
						max-width: 90%;
						width: 90%;
					}
				}
			}

		}
		.gallery{
			width: 960px;
			max-width: 100%;
			@include pb(2);
			@media(min-width: $desktop){
				max-width: 66%;
				@include pb(0);
			}
		}

		.container-fluid{
			.sortBlocks{
				.textBlock{
					@media(min-width: $desktop) {
						@include space-r();
					}

				}
			}
			.reverseBlocks{
				.textBlock{
					@media(min-width: $desktop) {
						@include space-l();
					}
				}
			}

		}
		.container {
			.gallery {
				.slideShow {
					@media (min-width: $desktop) {
						width: 960px;
						max-width: 100%;
					}
				}

				.video,
				video,
				.image {
					width: 100%;
					@media (min-width: $desktop) {
						max-width: 960px;
					}

				}
			}
			.slick-prev {
				@media(min-width: $desktop) {
					margin-left: -50px;
				}
			}
			.slick-next {
				@media(min-width: $desktop) {
					margin-right: -50px;
				}
			}
		}
	}
	&.customLayout--fullcentered {
		.container.container-sm-0 {
			max-width: none;
			padding-left: 0; 
			padding-right: 0;
		}

		.bannersSlider {
			max-width: none;
		}
		
		.col-xl-8.gallery {
			flex: 0 0 100%;
			max-width: 100%;
		}

		.js-bannersSlider__slider {
			padding: 0 5px;

			@media(min-width: $desktop) {
				padding: 0 10px;
			}
		}

		&.pv-0 {
			.col-xl-8.gallery {
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		.sliderItem {
			position: relative;
		}

		.textBlock {
			position: absolute;
			top: 0;
			z-index: 2;
			height: inherit;
			width: 100%;
			height: 100%;
			width: 100%;
			text-align: center;	
			background-color: rgba(0,0,0,0.5);

			&.no-opacity {
				background-color: transparent;
			}

			.topic {
				font-family: Raleway,sans-serif;
      			font-size: 12px;
			}

			.flipdown {
				left: 0 !important;

				.countdown {
					justify-content: center;
					margin-top: 1rem;
	
					.timer {
						@media (max-width: 500px)  {
							width: 24%;
							margin-right: 8px;
						}
					} 
	
					.date-txt {
						font-size: 1;
						@media (max-width: 500px)  {
							font-size: .8rem;
						}
					}
					.number {
						font-size: 3rem;
						@media (max-width: 500px)  {
							font-size: 2rem;
						}
					}
				}
			}
			
		}
	}
}