.filters {
  background-color: $product-label;
  color: $black;
  padding: 40px 20px 40px 20px;
  font-family: $font-secondary;
  font-size: 16px;
  margin-bottom: 25px;
  @media (min-width: $desktop) {
    font-size: 11px;
  }
  @media(min-width: $desktop-XL){
    font-size: 16px;
  }
  .title {
    color: $black;
    font-family: $font-secondary;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
 
  .filters__item {

    padding: 3px 0;

    &.filters__item--unique {
      display: none;

      .filters__item__header {
        display: none;
      }

      .filters__item__content {
        &.collapse {
          display: block;
        }

        .form-check {
          &:last-of-type {
            padding-left: 30px;
            padding-top: 0;
            padding-bottom: 0;
          }

          input[type='checkbox'] {
            left: 5px;
            height: 15px;
            width: 15px;
            border-radius: 0;
            margin-top: 11px;
            border: 2px solid #020202;
          }
        }

        .form-check-label {
          font-weight: 700;
          letter-spacing: 2.24px;
          line-height: 36px;
          text-transform: uppercase;
        }
      }
    }


    .filters__item__content {
      font-family: $font-primary;
      font-weight: 400;
      letter-spacing: 0.32px;
      line-height: 34px;

      .form-check {
        padding-left: 50px;
        @media (min-width: $desktop) and (max-width:$desktop-XL) {
          line-height: 20px;
        }

          //         &:first-of-type {
//           padding-top: 10px;
//         }
//
         &:last-of-type {
           padding-bottom: 5px;
         }

        input[type='checkbox'] {
          appearance: none;
          height: 10px;
          width: 10px;
          border: 1px solid #020202;
          border-radius: 50px;

          &:hover{
            @media (hover: hover) {
              background-color: $black;
            }
            @media (hover: none) {
              background-color: inherit;
            }
          }

          &:checked {
            background-color: $black;
          }

          &:checked + label {
            color: $black;
            font-family: $font-primary;
            font-weight: 700;
          }

          &:disabled{
            color: $light-grey;
            font-family: $font-primary;
            font-weight: 400;
            background-color: $white;
            border: 1px solid $light-grey;
            &:hover {
              appearance: none;
              border: 1px solid $light-grey;
              background-color: $white;
            }
          }

          &:disabled + label{
            color: $light-grey;
            font-family: $font-primary;
            font-weight: 400;
          }
        }
      }

      &--selected {
        font-weight: 700;
        letter-spacing: 0.32px;
        line-height: 34px;
      }

      &--disabled {
        color: $product-grey;
        font-weight: 400;
        letter-spacing: 0.32px;
        line-height: 34px;
      }

      .form-check-label {
        display: inline;
      }

      .filter-checkbox {
        position: absolute;
        left: 30px;
        margin-top: 13px;
      }
    }

    // filters color
    .filters__item__content.filter-img[data-attr="NEW_COLOR"]{
      input{
        opacity: 0;
      }
    }
    .filters__item__content.filter-img.show[data-attr="NEW_COLOR"],
    .filters__item__content.filter-img.show[data-attr="NEW_DECOR_SPEC_COL"] {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      padding-left: 25px;
      .form-check {
        padding: 0;
        padding-left: 0;
        padding-top: 0;
      }
      label {
        display: block;
        padding: 0;
        width: 30px;
        height: 30px;
        margin: 5px 10px 10px 0;
        border: 1px solid #d8d7d7;
        border-radius: 50%;
        position: relative;
        &:hover{
          cursor: pointer;
        }
        &.disabled::after {
          content: "|";
          transform: rotate(45deg);
          display: block;
          position: absolute;
          top: 5px; 
          left: 50%;
          color: #000;
          font-size: 55px;
          font-weight: 100;
        }
      
      }
      input {
        opacity: 0;

        &:checked + label:after {
          content: ""; 
          text-align: center;
          display: block;
          width: 34px;
          height: 34px;
          border: 1px solid #000;
          border-radius: 50%;
          position: absolute;
          bottom: -3px;
          left: -3px;
        }

        &:disabled + label{
          &:before {
            height: 100%;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
          }

          &:after {
            //content: "|";
            //transform: rotate(45deg);
            display: block;
            position: absolute;
            //top: 3px; 
            //left: 45%;
            //font-size: 55px;
            //font-weight: 100;
            content: "x";
            top: -4px;
            left: 35%;
            color: #000;
            font-weight: 300;
            font-size: 16px;
          }
        }
      }
    }
  }
}


.filters__item__header {
  font-weight: 700;
  letter-spacing: 2.24px;
  line-height: 36px;
  text-transform: uppercase;
  display: flex;
  position: relative;
  padding-left:30px;

  
  @media (min-width: $desktop) {
    line-height: 16px;
  }
  @media(min-width: $desktop-XL){
    line-height: 28px;
  }
}

.filters__item__header, 
.filters_title {
  &:before, 
  &:after {
    content: "";
    width: 15px;
    height: 2px;
    display: inline-block;
    background-color: $black;
  
    transition: all .3s ease-out;

    position: absolute;
    top: 0;
    left: 5px;

    transform: rotate(180deg);

    margin-top: 12px;
    margin-right: 10px;

  }

  &.collapsed {
    &:before { transform: rotate(0deg); }
    &:after {  transform: rotate(90deg); }
  }
}

.filters_title{
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: .5rem;

  @media (max-width: $mobile) {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 4px 0;
    position: relative;
  }

  &:before, 
  &:after {
    right: 0;
    left: auto;
    margin-right: 0;
    margin-top: 19px;
  }
}
 

.filters_buttons {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    left: 0;
    width: 100%;
    padding: 0 37px;
    z-index: 9;
    background: $white;

    &:before {
      content: '';
      height: 1px;
      display: block;
      background: $black;
      width: 100%;
    }

    button {
      width: 49%;
      border: 1px solid $black;
      border-radius: 0;
      margin: 9px 0;
    }
}
 
.filters_active_title {
  font-weight: bold;
  font-size: 14px;
}

.filters_active {
  background: $dusty-grey;
  padding: 4px 15px 15px;
  width: 100%;
  margin-bottom: .5rem;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: inline-block; 
      background: $white;
      padding: 4px 4px;
      font-size: 15px;
      margin: 2px;
      border-radius: 3px; 
      line-height: 1.2; 

      position: relative;
      padding-right: 25px;
   }

   .filter_category {
        margin-right: 5px;
        font-size: 14px;
        text-transform: uppercase;

        :after {
          content:':'
        }
    }

   .remove-filter {
      background-color: transparent;
      border: none;
      padding: 0 5px;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;

      .icon {
        font-size:20px;

        &:before {
          content: 'x'; 
          display: inline-block;
        }
      }
    }
  }
}

.filters_total_results {
   font-weight: bold;
   font-size: 14px;
}

.sortBy-default{
  border-radius: unset;
  border: unset;
  border-bottom: 1px solid $color-primary;
  line-height: initial;
  min-width: 170px;
  @media (min-width: $mobile) {
    min-width: 200px;
  }
  .select2-selection--single{
    border-radius: unset;
    border: unset;

    .select2-selection__arrow b{
      border-color: $color-primary transparent transparent transparent;
    }
  }
  .select2-container--open{
    .select2-selection--single {
      .select2-selection__arrow b {
        border-color: transparent transparent $color-primary transparent;
      }
    }
  }
  &.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent $color-primary transparent;
  }
}

.ecommerce .select2-results__option{
  line-height: initial;
  padding:10px;
  @media (min-width: $mobile) {
    padding: 10px 10px;
  }
}
 
body.filters_opened {
  #product-list-container,
  .comparator-container {
    @include visuallyHidden;
  }

}

//elementi da nascondere/mostrare su desktop
@media (min-width: $mobile) {
  .filters_title:before,
  .filters_title:after,
  .filters_buttons,
  .filters_active {
      display: none !important;   
  }

  //disabilito bootstrap accordion sul titolo "Filtri"
  #product_filters.collapse {
      display: block;
      height: auto !important;
      visibility: visible;
  }

  .collapsing {
    transition: none !important;
  }
} 






