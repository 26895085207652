.swiper-container {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	display: block;
	@media(min-width: $desktop) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}


.timeline{
	width: 100%;
	&::before{
		@media(max-width: $desktop) {
			content: "";
			position: absolute;
			z-index: 2;
			display: block; 
			top: 120px;
			left: 18px;
			width: 1px;
			height: calc(100% - 150px);
		}
	}
	.swiper-pagination--decade,
	.timeline-nav{

		.scrollIcon {
			@media(min-width: $desktop) {
				display: none;
				top:0;
				right: 50px;
			}
			@media(min-width: $desktop-XL) {
				top:105px;
			}
		}
		.timeline-nav__item{
			width: auto;
			height: auto;
			text-align: center;
			background: transparent;
			color: $t-color-primary;
			position: relative;
			font-size: 14px;
			line-height: 28px;
			font-weight: 400;
			outline: none;
			cursor: pointer;
			opacity: 1;
		}
	}

	.timeline-wrapper{
		position: relative;
		width: 100vw;
		height: 500px;

		@media (min-width: $desktop){
			height: 580px;
		}

	}
	.timeline-slider{
		width: 100%;
		height: 100%;
		display: inline;
		overflow-x: scroll;
		cursor:grab;
		display: flex;

	}
	.slick-list{
		height: 100%;
		@media (min-width: $desktop) {
			display: contents;
		}
	}
	.slick-track{
		@media (min-width: $desktop) {
			height: 100%;
			position: relative;
			display: flex;
			justify-content: space-between;
		}

	}
	.slick-dots {
		li button:before{
			display: none;
		}
	}
	.timeline-slide{
		margin-right: 200px;
		margin-right: 200px;

	}
	.timeline-year{
		z-index: 5;
		font-size: 35px;
		color: $t-color-primary;
		padding: 15px 26px;
		transition: opacity 1.2s;
		position: absolute;
		right: 0;
		opacity: 0;
	}
	.slick-current{
		&:after{
			background-color: rgba($white, 0);
		}
		.timeline-year{
			opacity: 1;
		}
		.timeline-slide__content{
			opacity: 1;
		}
	}

}

.historyComponent {
	background-color: $black;
	color: $white;
	padding-top: 50px;
	padding-bottom: 100px;
	padding-left: 0px;
	padding-right: 0px;
	position: relative;
	@media (min-width: $desktop) {
		padding-left: 0; 
		padding-right: 0;

	}
	.historyComponent__slide {
		padding-left: 37px;
		padding-right: 37px;
		padding-bottom: 50px;
		@media (min-width: $tablet-P) {
			padding-left: 60px;
			padding-right: 60px;
		}
		@media (min-width: $desktop) {
			min-width: 100vw;
		}
		@media (min-width: $desktop-XL) {
			width: 100vw!important;
		}
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			min-width: auto;
		}
	}
	.historyComponent__video{
		margin-left: 0px;
		@media (min-width: $desktop) {
			max-height: 500px;
			max-width: 100%;
			margin-left: 0px;
		}
		@media (min-width: $desktop-XL) {
			margin-left: inherit;
			max-width: unset;
			width: 900px;
		}
	}
	.historyComponent__item {
		display: block;
		@media (min-width: $desktop) {
			width: unset;
			display: inline-flex;
		}
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			width: auto;
		}

		.historyComponent__textBlock{
			margin-bottom: 20px;
			@media (min-width: $desktop) {
				width:500px;
			}
			.historyComponent__title{
				width: 135px;
				color: $dark-grey;
				font-family: $font-secondary;
				font-size: 40px;
				font-weight: 400;
				letter-spacing: 0.64px;
				margin-bottom: 20px;

				@media (min-width: $desktop) {
					width: 325px;
					font-size: 45px;
					letter-spacing: 2.52px;
				}
			}
			.historyComponent__introduction{
				width: 301px;
				font-family: $font-primary;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				@media(min-width: $tablet-P) {
					width: 436px;
				}
				@media(min-width: $desktop) {
					font-weight: 300;
					letter-spacing: 0.64px;
					line-height: 30px;
				}
			}
			.historyComponent__link{
				margin-top: 20px;
				@media(min-width: $desktop) {
				}
			}
		}
		.historyComponent__imageSection {
			@media(min-width: $desktop) {
				height: 400px;
				width: 100%;
			}
			@media(min-width: $desktop-XL) {
				width: 700px;
				height: 500px;
			}
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				min-width: 700px;
			}

			img{
				@media(min-width: $desktop) {
					width: auto;
					height: 400px;
					max-width: unset;
				}
				@media(min-width: $desktop-XL) {
					width: inherit;
					height: 500px;
					max-width: inherit;
				}

			}
			.slick-track{
				margin: 0;
			}
			.historyComponent__gallery__slide{
				width: 100%;

				@media(min-width: $desktop) {
					width: unset;
					height: 400px;
				}
				@media(min-width: $desktop-XL) {
					max-width: 700px;
					height: 500px;
				}
			}
		}

	}
	.historyComponent__timeline{
		display: flex;
		height: 100px;
		justify-content: space-around;
	}
	.slider__item:first-of-type .historyComponent__slide:first-of-type {
		padding-left: 0px;
		padding-right: 0px;

		.historyComponent__textBlock{
			padding-left: 37px;
			padding-right: 37px;
			position: absolute;
			top:60px;

			@media (min-width: $tablet-P) {
				padding-left: 120px;
				padding-right: 120px;
			}

			@media (min-width: $desktop) {
				position: absolute;
				top: 50%;
				left: -20px;
				transform: translateY(-50%);
			}
			.historyComponent__title{
				width: 100%;
				color: $t-color-primary;
				font-size: 40px;

				.foodservice &,
				.instrument &{
					color: $dark-grey;
				}

				@media (min-width: $desktop) {
					width: 700px;
					font-size: 45px;
				}
			}
		}
		.historyComponent__imageSection{
			@media (min-width: $desktop) {
				width: 1000px;
			}
			img,
			picture{
				width: 100%;
			}
		}

	}
	.swiper-button-prev,.swiper-button-next {
		display: none;
		@media(min-width: $desktop) {
			display: unset;
		}
		background-image: unset;
		color: $white;
	}
	.swiper-button-disabled{
		display: none!important;
	}
}

.swiper-container {
	@media(min-width: $desktop) {
		width: 100%;
		height: 100%;
	}
}
.swiper-slide {
	flex-shrink: unset;

	@media(min-width: $desktop) {
		/* Center slide text vertically */
		width: auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		width: 100%;
	}
}
.swiper-wrapper{

	flex-direction: column;
	@media(min-width: $desktop) {
		flex-direction: row;
		top:50px;
	}
	@media(min-width: $desktop-XL) {
		top:50px;
	}
}

.swiper-container--decade,
.swiper-container--year{
	position: relative!important;
	display: flex;
	width: 100vw;

	.swiper-pagination--decade{
		@media(min-width: $desktop) {
			display: flex;
			justify-content: space-around;
			align-items: center;
			bottom: initial;
			height: 50px;
		}
		.swiper-pagination-bullet{
			color: $t-color-primary;
			font-weight: 100;
			border:none;
			&:focus-visible{
				outline: -webkit-focus-ring-color auto 1px;
				border-radius: 0;
			}
		}
		.swiper-pagination-bullet-active{
			font-weight: bold;
			font-size: 1.5rem;
		}
	}
	.swiper-pagination--year {
		bottom: unset;
		top: 0px;
		height: 50px;
		.swiper-pagination-bullet:only-child {
			visibility: hidden;
		}
	}
}

.swiper-pagination-bullet {
	width: 15px;
	height: 15px;
	vertical-align: bottom;
	background-color: transparent;
	border:1px solid $el-color-primary; 
	opacity: .5;
	&.swiper-pagination-bullet-active{
		opacity: 1;
		background-color: $el-color-primary;
	}
}


.slick-dots--white {
    .slick-dots {
        .slick-active {
            button {
                .foodservice .historyComponent & {
                    border-color: $color-secondary!important;
                }
            }
        }
    }
}

.instrument,
.foodservice{
	.historyComponent{
		background-color: $white;
		.swiper-container--decade .swiper-pagination--decade .swiper-pagination-bullet,
		.swiper-container--year .swiper-pagination--decade .swiper-pagination-bullet{
			color:$black;
			&:hover{
				text-decoration: underline;
			}
		}
		.historyComponent__introduction{
			color:$black;
		}
		.slick-dots--white .slick-dots .slick-active button {
			border-color:$black!important;
		}
		.swiper-button-prev,.swiper-button-next {
			color: $black;
		}
	}
}