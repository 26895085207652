:root {
  --color-gold: #baa483;
  --color-attention: rgba(160, 160, 160, 0.6);
  //setting by js
  --header-height: 66px;
  --main-menu-height: 66px;
  --breadcrumb-position: 150px;
  --top-position-menu: 0px;
  --page-margin: 120px;
  @media (min-width: 1200px) {
    --header-height: 120px;
    --main-menu-height: 120px;
  }
  @media (max-width: 1199.9px){
    --page-margin: 66px;
  }
}

.isLeaderboardVisible {
  --top-position-menu: 50px;
  @media (max-width: 767.9px){
    --page-margin: 146px;
    --top-position-menu: 80px;
  }
  @media (min-width: 768px) and (max-width: 1119.9px){
    --page-margin: 116px;
    --top-position-menu: 50px;
  }
}

.isOldMenu {
  --page-margin: 160px;
  @media (max-width: 1199.9px){
    --page-margin: 60px;
  }
}

.isOldMenuLeaderboardVisible {
  --top-position-menu: 50px;
  --page-margin: 210px;
  @media (max-width: 767.9px){
    --page-margin: 140px;
    --top-position-menu: 80px;
  }
  @media (min-width: 768px) and (max-width: 1119.9px){
    --page-margin: 110px;
    --top-position-menu: 50px;
  }
}

.d-m-none {
  display: none;
  @media (min-width: 1200px) {
    display: block;
  }
}
.d-d-none {
  display: block;
  @media (min-width: 1200px) {
    display: none;
  }
}
header.header {
  width: 100%;
  //position: fixed;
  //top: 0;
  //z-index: 100;
  .megaMenu#megaMenu {
    //position: inherit;
    //top: unset;

    @media screen and (min-width: 1200px) and (max-width: 1535.9px) {
      height: 100px;
      min-height: 100px;
    }

    @media screen and (min-width: 1536px) {
      height: var(--main-menu-height);
      min-height: 120px;
    }

  }
}
body {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
}

/*Bloccare lo schermo quando il menu è attivo*/
.lockScreenScrolling {
  height: 100vh;
}

//.base-layout:has(#megaMenu) {
//  margin-top: 0;
//}

#header {
  width: 100vw;
  max-width: 100%;
  height: 130px;
  position: fixed;
  z-index: 10000;
  top: 0;
  background-color: #000;
  padding: 15px 0;
  font-size: 16px;
}
:root {
  --menuHeight: 150px;
  --menuMobileHeight: 100px;
}

.mobileBurgerMenu{
  @media (max-width: 1199px) {
    ul{
      margin-bottom: 0!important;
      display: flex;
      flex-direction: column;
    }
    .menu-close{
      right: 24px;
      top: 23px;
      position: absolute;
      z-index: 10050;
      display: inline-block;
      font-weight: 900;
      background-image: url("#{$images}/icons/close.svg");
      background-size: contain;
      background-repeat: no-repeat;
      overflow:visible;
      width: 20px;
      height: 20px;
      background-position: 100%;
      border: none;
      color: transparent;
      background-color: transparent;
      padding: 6px 0;
    }
    .topMenu{
      display: block!important;
      padding: unset!important;
      flex-direction: column!important;
      font-size: unset!important;
      font-weight: unset!important;
      line-height: unset!important;
      letter-spacing: unset!important;
      ul.list-unstyled{
        gap: unset!important;
        li{
          padding:16px 0!important;
        }
      }

    }
  }

  .topMenu__leftMenu {
    @media (max-width: 1200px) {
      padding-left: 1rem !important;
    }
  }

//   @media screen and (min-width: 992px) and (max-width:1200px) {
//     .menu-close{
//       display: none;
//     }
//   }
}


#megaMenu {

  .slick-track {
    margin-left: unset;
  }
  @media screen and (max-width: 1199px) {
    padding-bottom: 0;
  }
  background-color: var(--color-primary);
  width: 100%;
  padding: 0 0;
  padding-bottom: 50px;
  position: fixed;
  top: var(--top-position-menu);
  z-index: 100;
  @include font(Mulish);
  letter-spacing: .8px;
  font-weight: 400;
  line-height: 22.4px;
  @media screen and (min-width: 1920px) {
    max-width: none;
  }

  a {
    position: relative;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .backgroundLayer {
    &.active {
      width: 100%;
      position: absolute;
      left: 0;
      z-index: -1;
      height: calc(90vh - var(--header-height));
      @media screen and (min-width: 1201px) {
        height: var(--header-height);
      }
    }
  }

  .tag-image {
    position: absolute;
    color: var(--color-primary);
    background-color: var(--color-secondary);
    padding:  5px;
    font-weight: 900;
    font-size: .69rem;
    line-height: 0.8!important;
    top:5%;
    left: 0;
    transform: unset!important;
    box-shadow: 0 2px 5px 0 #0000001A;
  }

  .topMenu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: .8rem;
    font-weight: 200;
    line-height: 14px;
    letter-spacing: 0.8px;
    padding:1% 4%;
    margin: 0;
    @media screen and (max-width: 1199px) {
      padding-left:20px;
      padding-right: 20px;
    }

    .topMenu__leftMenu,
    .topMenu__centerMenu,
    .topMenu__rightMenu {
      ul {
        display: inline-flex;
        gap: 40px;
        margin-bottom: unset;
      }
    }

    .topMenu__centerMenu {
      //width: 220px;
      //height: 39px;
    }
    .topMenu__rightMenu{
      &> ul{
        display: flex;
        justify-content: end;
        float: right;
      }
    }
  }

  .mainMenu {
    display: block;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 1199px) {
      display: flex;
      // padding-top: 15px;
      // padding-left:37px;
      // padding-right: 37px;
      padding: 15px 20px;
    }
    .mainMenu__leftMenu {
      //width: 100px;

      @media screen and (min-width: 1200px) {
        width: max-content;
        display: contents;
        .rightSideMenu {
          & > .body {
            padding-left: 4%;
            padding-bottom: 10px;
          }
        }
      }
    }
    .mainMenu__rightMenu {
      display: flex;
      @media screen and (min-width: 1200px) {
        width: max-content;
        position: absolute;
        top: 70px;
        right: 4%;
//         margin-top: -10px;
        .level_1{
          padding-top: 0;
        }
        .level_1:not(.tpl_fullWidth) {
          &>.body{
            &>ul.list-unstyled{
              &>li:first-of-type{
                padding-top: 0;
              }
            }
          }
        }
      }
      @media (min-width: $desktop) and (max-width:1535.9px) {
        top: 55px;
      }

      /* ecommerce */
      //.header_layer {
      //  top: var(--header-height);
      //}
    }

    .menu-wrapper .hamburger-menu {
      margin: 2px 5px 0 24px;
      width: 20px;

      @media (min-width: 1200px) and (max-width: 1367px){
        zoom: 0.7;
        top: 7px;
      }
    }
  }



  #mobileBurgerMenu,
  #rightSideMenu {
    @media screen and (max-width: 1199px) {
      .header_layer {
        position: initial;
        bottom: 0;
        right: 0%;
        top: 0;
        .menu-container .menu-list {
          padding-left: 0;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      & > .body {
        & > ul {
          display: inline-flex;

          & > li.hasSubMenu {
            & > .sideMenuLevel {
              display: none;
              &.active {
                display: flex;
              }
            }
          }
        }
      }
    }
//     &.level_0,
//     .level_1:not(.tpl_fullWidth) {
//       @media screen and (min-width: 1024px) and (max-width: 1199px) {
//         width: 50%;
//       }
//     }
  }

  .tpl_card,
  .tpl_album,
  .tpl_gallery,
  .tpl_oneColumnOnlyText{
    & > .body {
      ul{
        li{
          a{
            span{
              line-height: 1.2;
            }
          }
        }
      }
    }
  }
  .tpl_oneColumnOnlyText{
    @media (min-width: 1200px) {
      margin-top: 10px;
    }
  }

  .rightSideMenu,
  .level_2,
  .sideMenuLevel {
    @media (max-width: 1199px) {
      position: fixed;
      height: 100%;
      width: 100%;
      left: 100%;
      z-index: 10010;
      top: var(--top-position-menu);
      background-color: var(--color-primary);
      padding: 20px;
      padding-top: 0;
      &.active {
        left: 0;
        overflow: auto;
        z-index: 11000;
        overflow-x: hidden;
      }

      .header {
        display: flex;
        justify-content: space-between;
        position: sticky;
        width: 100vw;
        background-color: black;
        margin-left: -37px;
        top: 0;
        padding: 45px 37px 20px 37px;
        z-index: 100;

        @media (max-width: 1199px) {
          padding: 20px;
          top: 0;
          margin-left: -20px;
        }

        .menu-current {
          width: 100%;
          margin-right: 20px;

          &.backSideMenu {
            @media (max-width: 1199px) {
              //padding-top: 11px;
            }
          }

          & > a {
            width: 100%;
            display: block;
            font-size: .7rem;
          }
          img {
            width: 100px;
          }
        }
      }
      .body {
        //margin-top: 15px;
        @media (min-width: 1200px) {
          margin-top: 50px;
        }
        li {
          padding: .8rem 0;

          @media (max-width: 1199px) {
            max-width: 100%;
          }

          @media (min-width: 1200px) {
            padding: 1rem 0;
          }
        }
      }
    }
  }




    /* underline on hover link */
  .topMenu__rightMenu,
  .topMenu__leftMenu {
    ul {
      li {
        a {
          text-decoration: none;
          display: inline-block;
          cursor: pointer;
          line-height: 1.5rem;
          &:after {
            transition: all ease-in-out 0.2s;
            background: none repeat scroll 0 0 #ffffff;
            content: "";
            display: block;
            height: 1px;
            width: 0;
          }
          &:hover:after {
            width: 100%;
          }
        }
      }
    }
  }
  /* end - underline on hover link */
  @media screen and (min-width: 1200px) {
    .linkCard{
      &:hover{
        & > a{
          &:has(img){
            & > span:not(.tag-image){
              color:#b6b6b6;
              &:after{
                width: 0!important;
              }
            }
          }
        }
      }
    }
    .hasSubMenu,
    .noSubMenu {
      > a {
        text-decoration: none;
        > span {
          text-decoration: none;
          display: inline-block;
          cursor: pointer;
          line-height: 1.5rem;
          text-align: left;
          &:after {
            transition: all ease-in-out 0.2s;
            background: none repeat scroll 0 0 #ffffff;
            content: "";
            display: block;
            height: 1px;
            width: 0;
          }
          &:hover:after {
            width: 100%;
          }
        }
      }

      &:has(> .active) {
        > a[href*=""]{
          > span {
            &:after {
              transition: all ease-in-out 0.2s;
              background: none repeat scroll 0 0 #ffffff;
              width: 100%;
              content: "";
            }
          }
        }
      }
    }
  }


  .level_0 {
    & > .body {
      @media screen and (max-width: 1199px) {
        //padding-top: 30px;
      }
    }
    @media screen and (min-width: 1200px) {
      & > .body > .list-unstyled > .hasSubMenu > a:before {
        display: none;
      }

      & > .hasSubMenu {
        align-self: center;
        position: inherit;

        &:before {
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
        }
      }

      & > .body {
        & > ul {
          column-gap: 40px;
          flex-wrap: wrap;
        }
      }
    }
  }

  .mainMenu__leftMenu .level_1>.body{

    @media screen and (max-width: 1199px) {
      //padding-top: 30px;
    }

    @media screen and (min-width: 1200px) {
      margin-bottom: 50px;
    }
  }

  .level_1 {
    .hasSubMenu,
    .noSubMenu {
      &.joinLevels {
        > a.link--title {
          color: #baa483;
          margin-bottom: 0;
          text-transform: uppercase;
          font-weight: 500;
          font-family: 'Mulish', sans-serif;

          @media screen and (max-width: 1023px) {
            margin-bottom: 0;
          }
          @media screen and (min-width: 1200px) {
            min-height: 25px;
          }
          span {
            cursor: default;

            &:hover {
              &:after {
                width: 0;
              }
            }
          }
        }
      }

      &:not(.aesthetics-line),
      &:not(.inspirations) {
        .level_2{
          @media screen and (min-width: 1200px) {
            min-width: 233.4%;
          }
        }
      }
    }

    &.aesthetics-line,
    &.inspirations{
      &.active {

        @media screen and (max-width: 1199px) {
          height: 100%;
          overflow-x: hidden;
        }
        @media screen and (min-width: 1200px) {
          height: unset !important;
        }

        &:after {
          display: none;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      position: absolute;
      top: calc(var(--page-margin) - var(--top-position-menu));
      left: 0;
      min-height: auto;
      height: auto;
      max-height: calc(100vh - var(--page-margin));
      width: 30%;
      background-color: #000;
      padding-top: 18px;
      padding-left: calc(4% - 10px);
      font-size: 90%;
      z-index: 10;
      &:not(.tpl_fullWidth) {
        .hasSubMenu {
          &:has(> .level_2.active) {
            > a {
              font-weight: bold;
              transition: all 0.1s ease;

              //&:before {
              .menu-icon{
                transform: rotate(-90deg);
                transition: all 0.1s ease;
              }
            }

            background-color: #151515;
          }

          > .sideMenuLevel {
            .body > ul > li {
              padding: 1% 1% 1% 0;
            }
          }
        }
      }

      & > .body {
        height: auto;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        & > .hasSubMenu {
          position: relative;
          > a:before {
            transition: all 0.1s ease;
          }
          &:has(> .level_2.active) {
            > a {
              font-weight: bold;
              &:before {
                transform: rotate(-180deg);
                transition: all 0.1s ease;
              }
            }
            background-color: #151515;
          }
          > .sideMenuLevel .body > ul > li {
            padding: .5% 30px .5% 0.5rem;
          }
          &.joinLevels {
            > a {
              color: #baa483;
              padding-left: 0.5rem;
              margin-top: 1rem;
              margin-bottom: 1rem;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  .level_1:not(.tpl_fullWidth) {
    .hasSubMenu,
    .noSubMenu {
      &.joinLevels {
        > a.link--title {
          margin-top: 1rem;
          @media screen and (max-width: 1023px) {
            margin-top: 0;
          }
        }
      }
    }
    &>.body{
      &>ul.list-unstyled{
        &>li:first-of-type{
          padding-top:1.5%;
        }
      }
    }
  }

  .level_2 {
    z-index: 10020;


    @media screen and (min-width: 1200px) {
      position: absolute;
      top: 0;
      left: 100%;
      height:100%;
      padding-top: 18px;
      background: var(--color-primary);
      &:not(.one-wrapper-menu),
      &:not(.tpl_list){
        max-width: 70%;
        & > .body {
          overflow-y: auto;
          width: 100%;
          height: 100%;
          overflow-x: hidden;
          background-color: #151515;
          padding: 0 5% 20px;
          & > .list-unstyled {
            & > li {
              min-width: 48%;
            }
          }
        }
      }
    }
  }

  .section__title,
  a.section__title,
  a:not([href]).section__title,
  a:not([href]):not([tabindex]).section__title {
    color: gold;
    grid-column: 1 / span 2;
    margin-top: 25px;
  }

  .one-wrapper-menu {
    > .body {
      margin-top: 50px;
      padding-bottom: 100px;
      @media (max-width: 1199px) {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    > .level_1 {
      max-width: 100%;
      //min-width: 100%;
      padding: 20px !important;
      padding-top: 0 !important;

      @media screen and (min-width: 1200px) {
        padding: 0 3.5% !important;
        padding-left: calc(4% - 10px)!important;
      }
    }

    .level_2 {
      max-width: 100%;
      height: auto;
      & > .body {
        width: 100%;
        min-width: unset!important;
        height: 100%;
        background-color: rgb(65, 63, 63);
        & > .list-unstyled {
          & > li {
            min-width: auto;
          }
        }
      }
      .header {
        display: none;
      }
    }
    .inspirations{
      .level_2 {
        min-width: 100%!important;
        & > .body {
          & > .list-unstyled {
            & > li {
              min-width: unset;
              padding-left: 0;
              text-align: left;
              margin-top:5px;

              @media screen and (max-width: 1199px) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .sideMenuLevel {
      padding: 0;
    }

    .body {
      .level_2 {
        position: static;
        background-color: unset;
        width: 100%;

        > .body {
          background: none;
        }
      }
    }
  }

  /* Burger menu mobile */
  .burgerMenu {
    @media (max-width: 1199px) {
      li.toggle {
        position: relative;
      }
      .menu-link {
        text-transform: initial;
        font-weight: 400;

        &:after {
          display: none;
        }

        &:before {
          position: absolute;
          content: url("#{$images}/icons/arrow-down.svg");
        }
      }
    }
  }

  .slick-arrow {
    top:50%;
    &.slick-prev {
      left: -16%;
      @media screen and (min-width: $tablet-P) {
        left: -7%;
      }
      @media screen and (min-width: $tablet-L) {
        left: -10%;
      }
    }
    &.slick-next {
      right: -14%;
      @media screen and (min-width: $tablet-P) {
        right: -7%;
      }
      @media screen and (min-width: 1024px) {
        right: -7.5%;
      }
    }
  }

  /* Ordinamento e titoli vuoti */
  .order-lg-2:has(>.link--title span:empty){
    >a.link--title{
      height:0!important;
      min-height:unset!important;
      margin-top:0!important;
    }
  }
}

.smeg__brand {
  display: flex;
  justify-content: center;
  //max-width: 200px;
  @media screen and (min-width: 1200px) {
    margin: 0 auto;
  }
  img {
    width: auto;
    height: 39px;
    @media (max-width: 767.9px) {
      height: 20px;
    }
    @media (min-width: 768px) and (max-width: 991.9px){
      height: 30px;
    }
    @media (min-width: 992px) and (max-width: 1199.9px){
      height: 35px;
    }
  }
}

.hasSubMenu {
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (min-width: 1200px) {
    position: inherit;
    display: block;

    & > .sideMenuLevel {
      display: none;

      &.active {
        display: block;
      }

      .header {
        display: none;
      }

      .body {
        padding: 0;

        & > ul {
          & > li {
            padding: .5% 30px .5% 10px;
          }
        }
      }
    }
    .level_3 {
      width: calc(100% - 170px - 170px - 225px);
      left: calc(170px + 225px);
      .body{
        ul.list-unstyled.tpl_oneColumnOnlyText {
          li:first-of-type{
            padding-top:0!important;
          }
        }
      }
    }
  }

  & > a {
    @media screen and (min-width: 1200px) {
      position: relative;
      display: flex;
      align-items: center;
    }

    // &:before {
    //   position: absolute;
    //   content: url("#{$images}/icons/arrow-down.svg");

    //   right: 7px;
    //   z-index: -1;
    //   @media screen and (min-width: 1200px) {
    //     z-index: 0;
    //     transition: all 0.1s ease;
    //     right: 0px;
    //   }
    // }
    .menu-icon {

        position: absolute;
        background: url("#{$images}/icons/arrow-down.svg");
        right: 7px;
        // z-index: -1;
        height: 12px;
        width: 12px;
//         transform: rotate(-90deg);

        @media screen and (min-width: 1200px) {
          // z-index: 0;
          transition: all 0.1s ease;
          right: 0px;
        }
        @media (min-width: 1200px) and (max-width: 1367px){
          zoom: 0.7;
        }
    }
  }
}

.icon--comeBack{
  &:before {
    //position: absolute;
    //content: url("#{$images}/icons/arrow-down.svg");
    //transform: rotate(90deg);
    //display: block;

    // position: absolute;
    // content: '';
    // transform: rotate(90deg);
    // display: block;
    // background-image: url("#{$images}/icons/arrow-down.svg");
    // /* background-size: 50px 50px; */
    // background-repeat: no-repeat;
    // background-size: contain;
    // width: 10px;
    // height: 10px;
  }
  .menu-icon-back {
    position: absolute;
    transform: rotate(90deg);
    display: block;
    background-image: url("#{$images}/icons/arrow-down.svg");
    /* background-size: 50px 50px; */
    background-repeat: no-repeat;
    background-size: contain;
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);

    @media (min-width: 1200px) and (max-width: 1367px){
      zoom: 0.7;
    }
  }
}

.hasSubMenu,
.noSubMenu {
  > a {
    width: 100%;
    display: block;

    @media screen and (min-width: 1200px) {
      display: flex;
    }
  }
}

.hasSubMenu {
  > a {
    padding-right: 10px;
    display: flex;
    align-items: center;
  }
}

.noSubMenu > a > span:empty {
  height: 10px;
}

/*presentare nella stessa pagina menu e sottomenu*/
.joinLevels {
  display: block !important;

  &.hasSubMenu {
    .menu-icon {
      display: none;
    }
  }
  .hasSubMenu {
    & > a,
    .menu-icon {
      display: none;
    }
  }
  & > .sideMenuLevel {
    position: inherit;
    display: block;
    height: 100%;
    width: auto;
    left: unset;
    z-index: 10010;
    top: unset;
    padding: unset;

    & > .header {
      display: none;
    }
    .body {
      padding: unset;
    }
  }

  & > a:before {
    display: none;
  }

  &.d-m-none {
    display: none !important;
    @media (min-width: 1200px) {
      display: block;
    }
  }
  &.d-d-none {
    display: block;
    @media (min-width: 1200px) {
      display: none !important;
    }
  }

  .hasSubMenu {
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }

    .sideMenuLevel {
      position: inherit;
      height: auto;
      left: unset;
      padding: unset;
      @media screen and (min-width: 1200px) {
        display: block !important;
      }

      & > .header {
        display: none;
      }
    }
  }
}

/*presentare in più colonne i menu dello stesso livello*/
.multiColumns {
  .body {
    @media screen and (max-width: 1199px) {
      padding-bottom: 0 !important;
    }
  }
  & > .body {
    & > ul {
      & > li {
        .level_3 {
          .header {
            @media screen and (max-width: 1199px) {
              display: none !important;
            }
          }
        }
      }
    }
  }
  .sideMenuLevel {
    @media screen and (max-width: 1199px) {
      padding: 0 !important;
    }
  }
}

.one-wrapper-menu {
  .sideMenuLevel {
    &.level_2 {
      > .body {
        > .list-unstyled {
          display: flex !important;
        }
      }
    }

    &.aesthetics-line {
      .hasSubMenu>.sideMenuLevel .body>ul>li {
        padding: .5% 45px .5% 10px;
      }
      .sideMenuLevel.level_2 {
        > .body {
          > .list-unstyled {
            @media screen and (max-width: 1199px) {
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}

/*stili typogratici*/
.menuItem__empty{
  display: block;
  height: 0.5rem;
  border-top: unset;
  margin-top: 1rem;
  margin-left: 10px;
  margin-right: 25px;
  > a {
    display: none
  }
}

.menuItem__line {
  display: block;
  height: 0;
  border: 0;
  border-top: 1px solid var(--color-gold);
  margin-top: 1%;
  margin-left: 10px;
  margin-right: 25px;

  @media screen and (max-width: 1199px) {
    padding: 0 !important;
    margin-left: 0;
    margin-right: 0;
    margin-top: 3%;
    margin-bottom: 2%;
  }

  > a {
    display: none;
  }
}

.line {
  border-top: 1px solid var(--color-gold);
  width: 100%;
  height: 1px;
  display: block;
}
span:has(hr.line) {
  width: 100%;
}

/*templates tpl*/

.position_right {
  .tpl_album {
    &.tpl_columns_4 {
      @media screen and (min-width: 1200px) {
        width: unset !important;
      }
    }
  }
}
.position_left,
.position_right {
  @media screen and (min-width: 1024px) {
    margin-bottom: 25px;
  }
}

.tpl_list,
.tpl_album {
  @media screen and (min-width: 1200px) {
    width: 60%;
  }

  .body {
    padding-left: 0rem !important;
    ul {
      display: flex;
      grid-gap: 15px;
      grid-template-columns: repeat(2, 1fr);
      flex-wrap: wrap;

      @media screen and (min-width: 1201px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
      & > .hasSubMenu {
        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }
      }
    }

    .list-unstyled {
      padding: 0;
      flex-grow: 1;
      white-space: nowrap;

      @media screen and (max-width: 960px) {
        white-space: initial;
      }
      .body {
        padding-left: 0;
        padding-right: 0;
      }

      li {
        padding-left: 0 !important;
        padding-top:15px!important;
        &.linkCard {
          span {
            max-width: 150px;
            white-space: normal;

            @media (max-width: 1199px) {
              line-height: 1.4 !important;
              font-size: .8rem;
            }
          }
        }
      }
    }

    li.linkCard {
      a {
        display: flex;
        flex-direction: column;

        img {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.tpl_album {
  .body {
    ul.list-unstyled {

      display: grid;
      grid-template-rows: auto;

      @media screen and (min-width: 1200px) {
        grid-gap: 3%;
      }

      & > li {
        & > a {
          //max-width: 150px;
          & > img {
            width: 100%;
          }
        }
      }
    }
  }
}

.tpl_album.tpl_columns_3 {
  .body {
    ul.list-unstyled {
      display: grid;
      grid-template-columns: repeat(3, 30%);

      @media screen and (max-width: 1199px) {
        grid-template-columns: repeat(2, 47.6%);
      }
    }
  }
}

.tpl_album.tpl_columns_4 {
  .body {
    ul.list-unstyled {
      display: grid;
      grid-template-columns: repeat(4, 20%);

      @media screen and (max-width: 1199px) {
        grid-template-columns: repeat(2, 49%);
      }
    }
  }
}

.level_1 {
  &.tpl_album {
    .body {
      ul {
        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, 1fr);
        }
      }
    }
  }
}

.tpl_list {
  min-width: unset!important;
  .list-unstyled {
    @media (max-width: $desktop) {
      flex-direction: unset!important;
    }
    .linkCard {
      @media (max-width: 960px) {
        width: 45%;
        img {
          width: 100%;
        }
      }
    }
  }
}

.tpl_album {
  .body {
    .list-unstyled {
      width: 100%;
      @media screen and (max-width: 1199px) {
        display: grid;
        width: 100% !important;
        li {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
/* links animation */
.tpl_oneColumnOnlyText{
  li {
    a {
      span {
        &:hover {
          color: #bebebe;

          &:after {
            border: none;
            background: unset;
            width: 0 !important;
          }
        }
      }
    }
  }
}

.tpl_card {
  position: relative;
  .body {
    padding: 0 !important;
    ul {
      display: flex;
      flex-direction: column;
      //margin-bottom: 50px;

      li {
        padding-left:0;
        .body {
          ul {
            display: flex;
            justify-content: space-between;
            li {
              width: 300px;
            }
          }
        }
        a{
          span{
            &:hover{
              color: #bebebe;
              &:after{
                border:none;
                background: unset;
                width: 0!important;

              }
            }
          }
        }
        &.noSubMenu{
          padding: 0.6rem 0.8rem 0 0!important;
        }
      }
    }
  }
  >.body{
    >ul{
      >li.noSubMenu:first-of-type{
          padding-top: 10px!important;
      }
    }
  }
  .link--viewAll {
    margin-top: 50px;
    position: absolute;
    bottom: 0;
    display: block;
    text-align: left;
  }
}
.tpl_fullWidth {
  ul.list-unstyled:has(.tpl_card) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }
  }
}
.tpl_fullWidth.inspirations {
  ul.list-unstyled:has(.tpl_card) {
    &>li {
      &.noSubMenu,
      &.hasSubMenu:has(.tpl_card) {
        @media screen and (min-width: 1024px) {
          width: 19%;
        }
      }
    }
  }
}
/* tag-image */
.tpl_album,
.tpl_gallery {
  .body {
    ul {
      li.linkCard {
        position: relative;
        padding-top: 15px!important;
        .tag-image {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 0;
        }
      }
    }
  }
}

.tpl_gallery {
  .body {
    width: 500px;
    max-width: 100%;
    padding-left: 0rem !important;
    ul {
      display: flex;
      grid-gap: 15px;
      grid-template-columns: repeat(2, 1fr);

      li.linkCard {
        position: relative;
        padding: 0px !important;
        padding-top: 15px!important;
        a {
          img {
            max-width: 100%;
            width: 100%;
          }
        }
        .tag-image {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 0;
        }
      }

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(8, 1fr);
      }
      & > .hasSubMenu {
        @media screen and (max-width: 1023px) {
          flex-direction: column;
        }
      }
      .slick-track{
        display: inherit;
        min-width: 100%;
      }
    }

    .list-gallery {
      display: block;
      padding: 0;
      width: 100%;
      flex-grow: 1;
      white-space: nowrap;
      gap: 40px;
      @media screen and (max-width: 960px) {
        white-space: initial;
      }
      .body {
        padding-left: 0;
        padding-right: 0;
      }

      @media screen and (max-width: $mobile) {
        .slick-track {
          li {
            img {
              width: 100% !important;
            }
          }
        }
      }

      li {
        padding: 0 !important;
        &.linkCard {
          padding: 0;
          margin: 0 1% 0 0;
          flex-grow: 1;
          width: 30%;
          display: inline-block;
          @media screen and (max-width: 1199px) {
            margin-right: 8px;
//             margin: 0 .8% 0 0;
          }
          span {
            max-width: 150px;
            white-space: normal;
            @media screen and (max-width: 1199px) {
              line-height: 1.4 !important;
              font-size: .8rem;
            }
          }
        }
      }
    }

    li.linkCard {
      a {
        display: flex;
        flex-direction: column;

        img {
          margin-bottom: 5px;
        }
      }
    }
  }
  &.js_slick_gallery{
    .body {
      width: unset;
      .sliderStartDesk {
        .slick-list {
          overflow: hidden;
          margin: 0;
          padding: 0;
          width: 100%;
          padding-right: calc(100% - 500px);
          @media screen and (min-width: 1024px) {
            width: 97%;
          }
        }
      }
      .noSliderDesk{
        .slick-list {
          .linkCard{
            @media screen and (min-width: 1200px) {
              margin-right: 3%!important;
            }
          }
        }
        &.tpl_gallery_rect{
          .linkCard{
            @media screen and (min-width: 1200px) {
              margin-right: 5%!important;
            }
          }
        }
      }
    }
  }
}
.level_1 {
  &.tpl_gallery {
    .body {
      ul {
        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(8, 1fr);
        }
      }
    }
  }
}

.inspirations {
  .list-unstyled {
    column-gap: 10px !important;
    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
    .linkCard {
      position: relative;
      > a {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > img {
          width: 100%;
          margin-bottom: 15px;
          @media screen and (max-width: 1199px) {
            margin-bottom: 0;
          }
        }
      }
      .tpl_card {
        .body {
          .list-unstyled {
            gap: 0px;
          }
        }
      }
      .link--viewAll {
        margin-top: 50px;
        position: absolute;
        bottom: 0;
        display: block;
        text-align: left;
        font-weight: 400;
      }
    }
  }
}

.level_3 {
  .header {
    @media screen and (max-width: 1199px) {
      display: none !important;
    }
  }
}

/*template per menu di livello zero come "ispiazioni"*/
.tpl_fullWidth {
  @media screen and (min-width: 1200px) {
    width: 100% !important;
    & > .body {
      margin: 0 auto;
      padding: unset !important;
    }
    .sideMenuLevel {
      position: inherit;
      left: unset;
      width: 100%;
      .body {
        width: 100%;
        padding:unset!important;
      }
    }
  }

  .tpl_album {
    .list-unstyled {
      width: 100%;
      @media screen and (max-width: 1199px) {
        li {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

#megaMenu .joinLevels .sideMenuLevel {
  @media screen and (max-width: 1199px) {
    position: static;
  }
}

/* Breadcrumbs */
.new-menu{
  &+.breadcrumb__row nav[aria-label=breadcrumb] .breadcrumb .breadcrumb-item{
    font-size: 0.625rem;
  }
}

/* new footer */
.footer__container{
  padding: 0 4%;
}

/* Select your country */
.selectYourCountry{
  &__link{
    font-size: .8rem;
    font-weight: 400!important;
    &:after {
      content: url("#{$images}/icons/arrow-down.svg");
      transform: rotate(-90deg);
      left: 5px;
      display: inline-block;
      position: relative;
    }
  }
}

/* ===== Scrollbar CSS ===== */
#megaMenu {
  /* Firefox */
  * {
    //da testare correttamente auto prima none
    scrollbar-width: none;
//     @media (max-width: 1199px) {
//         scrollbar-width: none;
//     }
    scrollbar-color: #fff #151515;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 2px;
  }

  *::-webkit-scrollbar-track {
    background: #151515;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 10px;
    border: none;
  }
}

//buger-menu
#megaMenu .burgerMenu {
  top: var(--page-margin);

  .hasSubMenu {
    & > a:before {
      z-index: 1;
    }
    &:has(> .level_1.active) {
      > a {
        transition: all 0.1s ease;
        &:before {
          transform: rotate(-180deg);
          transition: all 0.1s ease;
        }
      }
    }
  }

  .sideMenuLevel .header .menu-current > a {
    color: $white;
  }
  .menu-close {
    color: $white;
  }
  @media screen and (min-width: 1200px) {
    .menu-list {
      padding-left: 50px;
      padding-right: calc(50px + 4%);

      & > li {
        & > .link--title {
          padding-top: 10px;
          padding-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .level_1 {
    @media screen and (min-width: 1200px) {
      position: inherit;
      left: 0;
      height: auto;
      min-width: 30vw;
      max-width: 30vw;
      background-color: #000;
      color: $t-color-primary;
      padding-left: 2%;
      top: var(--header-height);
    }
  }
  .menu-list {
    .link--title {
      span {
        color: white;
      }
    }
  }
}

//menu cms icon
.hippo-show-components {
  .wrapper-menu-cms {
    position: relative;
    .hippo-overlay-element {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.newMenu--close {
  @media screen and (min-width: 1200px) {
    right: 2%;
    top:30px;
    position: absolute;
    z-index: 10050;
    transform: translateX(-50%);
    display: inline-block;
    font-weight: 900;
    background-image: url("#{$images}/icons/close.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    background-position: 100%;
    border: none;
    color: transparent;
    background-color: transparent;
    padding: 6px 0;
    cursor: pointer;

    @media (min-width: 1200px) and (max-width: 1367px){
      zoom: 0.7;
    }

  }
}

a.animation--hoverUnderline{
  @media screen and (min-width: 1200px) {
    text-decoration: none;
    > span {
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      line-height: 1.5rem;
      &:after {
        transition: all ease-in-out 0.2s;
        background: none repeat scroll 0 0 #ffffff;
        content: "";
        display: block;
        height: 1px;
        width: 0;
      }
      &:hover:after {
        width: 100%;
      }
    }
  }
}

/* The SeeMore class on the side menu is triggered if <li> items are taller than the menu height */
.seeMore{
  &.seeMore--show {
    &:after {
      content: 'See more';
      display: block;
      padding: 1% 1% 1% 2%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-family: 'Mulish', sans-serif;
      font-size: .8rem;
      color: #b6b6b6;
    }
  }
}

/* Visualizzazione tablet */
// @media screen and (min-width: $tablet-L) and (max-width:$desktop) {
//   #rightSideMenu.level_0.active,
//   #megaMenu .sideMenuLevel.active:not(.multiColumns) {
//     left:50%;
//   }
//   #megaMenu .sideMenuLevel.active.tpl_fullWidth {
//     left:0%;
//   }
//   .mainMenu__rightMenu{
//     z-index: 99999;
//     position: inherit;
//   }
//
// }

#rightSideMenu{
    .hasSubMenu{
        @media screen and (min-width: 1200px) {
            &:has(> .level_1.active) {
                > a {
                  //&:before {
                  .menu-icon{
                    transform: rotate(-180deg);
                    transition: all 0.1s ease;
                  }
                }

            }
        }
    }
}


body.no-scroll {
  overflow: hidden
}

.level_2, .level_3{
    .tpl_card{
         & > .body {
           ul{
             li{
               a{
                display: flex;
                 img{
                   padding-right: 1rem;
                 }
               }
             }
           }
         }
    }
}

@media (max-width: 1199px) {
    .cookieSpace{
        height: calc(100% - 90px);
        overflow-x: hidden;
        overflow-y: scroll;
    }
}

@media (max-height: 670px) {
    .cookieSpace{
        height: calc(100% - 110px);
    }
}

@media (max-width: 1199px) {
    .level_1.active, .level_2.active{
        height: calc(100% - 70px) !important;
    }

}

.page-container{
  margin-top: var(--page-margin);
}