@media (min-width: $desktop) and (max-width:$desktop-XL) {
    //reset
    html,
    body {
        font-size: 10px!important;
        //background-color: rgb(0,0,0);
    }
    body{
        .products-list-page,
        .page__product-detail{
            background-color: white;
        }
    }
    p {
        font-size: 1rem;
        line-height: 2rem;
    }

    //utilities.scss
    //.base-layout {
    //    margin-top: 120px;
    //}
    .navbarCategories__item{
        font-size:.8rem;
    }
    //header.scss
    .header-nav {
        height: 60px;
    }
    .main-nav {
        height: 60px;
        padding-top: 5px;
    }
    .header-nav__store .store {
        margin: 0;
    }
    .header__mouseOver {
        padding: 1.3rem 0;
        .header__secondaryMenu {
            top: -99vh;
            padding-bottom: 0;
        }
    }
    .header__mouseOver.open .header__secondaryMenu{
        padding-top: 30px;
    }
 
    .menu-wrapper .hamburger-menu:before {
        background-size: 14px 14px;
        height: 30px;
        width: 20px;
    }

    .secondNav__inspirations .child__description p {
        font-size: 1rem;
        line-height: initial;
    }

    //animations.scss
    .action-link {
        font-size: 0.8rem;
    }
    .action-scroll .scroll-title {
        font-size: 0.9rem;
    }
    //header menu
    .header-nav__store .store__link {
        font-size: 0.7rem;
    }
    .main-nav .nav-first {
        font-size: 1rem;
    }
    .main-nav {
        padding-top: 0;
        align-items: center;
    }
    .main-nav .nav-second {
        font-size: 1.2rem;
    }
    .main-nav .nav-third {
        font-size: 1rem;
        line-height:2;
        padding: unset;
        a {
            height: 25px;
        }
    }
    //burger-menu.scss
    .burgerMenu .menu-link,
    .burgerMenu .menu-subLink {
        font-size: 1rem;
    }
    .burgerMenu .head {
        font-size: 1rem;
        line-height: unset;
    }
    list li.accordion-toggle, .menu-container .menu-list li.toggle {
        padding-top: 10px;
        padding-bottom: 0px;
    }

    //hero-banner.scss
    .heroBannerFullScreen {
        .heroBannerFullScreen__title {
            font-size: 2.6rem;
            line-height: 20px;
        }
        .heroBannerFullScreen__subTitle {
            font-size: 1rem;
        }
        .heroBannerFullScreen__textBlock .heroBannerFullScreen__topic {
            font-size: 0.8rem;
        }
    }

    .heroBannerComponent .heroBannerComponent__title {
        font-size: 2.6rem;
    }

    .heroBannerUnderlined .heroBannerUnderlined__content .heroBannerUnderlined__text .heroBannerUnderlined__text__title {
        font-size: 2.6rem;

    }

    .heroBannerBlocks {
        .heroBannerBlocks__info__title {
            font-size: 2.6rem;
        }
        .heroBannerBlocks__info__subTitle {
            font-size: 1rem;
        }
        .heroBannerBlocks__info__category {
            font-size: 1rem;
        }
    }

    //cards.scss
    .productsPreview .products {
        .title {
            font-size: 2.1rem;
        }
        .cta {
            font-size: 0.8rem;
        }
    }

    .cards-component {
        .cards__title {
            font-size: 1.3rem;
        }
        .cards__text {
            font-size: 1rem;
        }
        .cards__link {
            font-size: 0.8rem;
        }
    }
    .cards-grid{
        .cards-grid__gallery{
            .cards-grid__slidersBlock {
                .cards-grid__slide {
                    min-height: 300px!important;
                    .cards__card {
                        .cards__textBox {
                            .cards__title {
                                font-size: 1.3rem !important;
                            }
                            .cards__text {
                                p {
                                    line-height: 1.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //visual-box.scss
    .visualBox .visualBox__text {
        .visualBox__title {
            font-size: 2.1rem;
        }
        .visualBox__subTitle {
            font-size: 1rem;
        }
    }

    //newslist-preview.scss
    .newslist-preview {
        .section-header {
            .title {
                font-size: 2.1rem;
            }
        }
    }

    //text.component.scss
    .cmp_generalText.tpl_quote .textBlock p:before{
        margin-top: -10px;
    }
    .cmp_generalText.tpl_quote .textBlock p:after{
        margin-top: 10px;
    }

    //stories-grid.scss
    .storyItem__category {
        font-size: 0.8rem;
    }
    .storyItem__title {
        font-size: 1rem;
    }
    .storyHeader__title{
        font-size: 2.6rem;
    }

    //footer.scss
    .footer-nav {
        font-size: 1.1rem;
    }
    .disclaimer-nav {
        font-size: 0.8rem;
    }
    .footer .footer__newsletter label[for=subscribe] {
        font-size: 1.1rem;
    }
    .privacy-policy .form-check-label {
        font-size: 0.9rem;
        padding-left: 10px;
        padding-top: 5px;
        line-height: 20px;
    }
    .footer .footer__newsletter .form-group .footer__submit {
        line-height: 10px;
    }
    .footer .footer__social-icon .footer__social-icon__img {
        font-size: 20px;
    }
    
    #btn_backToTop {
        width: 40px;
        height: 40px;
    }
    #btn_backToTop:before {
        background-size: 17px 17px;
        background-position-x: center;
        background-position-y: 15px;
    }
    #btn_backToTop .backToTop_text{
        font-size:1.1rem;
    }

    //breadcrubs.scss
    .new-menu{
      &+.breadcrumb__row nav[aria-label=breadcrumb] .breadcrumb .breadcrumb-item{
        font-size: 0.8rem;
      }
    }

    //product-overview.scss
    .products-overview-page .products-list:not(.js-product-list-slider) .products-list__item .products-list__item__title {
        font-size: 1.5rem;
    }
    //product-manager.scss
    .products-manager-page .products-list.js-product-list-slider .products-list__item .products-list__item__title {
        font-size: 1.6rem;
    }
    //products-comparator.scss{}
    .comparator-container .comparator-sku-wrapper .comparator-sku-container .comparator-sku-element span {
        font-size: 1rem;
    }

    //products-list.scss
    .filters {
        font-size: 0.9rem;
    }
    .products-list .product-preview .product-preview__top .label {
        font-size: 0.8rem;
    }
    .products-list .product-preview__header .name {
        font-size: 1rem;
    }
    .products-list .product-preview__description {
        font-size: 0.8rem;
    }
    .filters__item__header.collapsed:before {
        width: 10px;
        height: 1px;
        top: -2px;
        left: 5px;
    }
    .filters .filters__item .filters__item__header.collapsed:after,
    .filters .filters__item .filters__item__header.collapsed:before {
        width: 10px;
        height: 0.5px;
        top: -4px;
    }
    .filters .filters__item .filters__item__header:not(.collapsed):after,
    .filters .filters__item .filters__item__header:not(.collapsed):before {
        content: "";
        width: 10px;
        height: 0.5px;
        top: -4px;
    }
    .filters .filters__item .filters__item__content .filter-checkbox {
        margin-top: 5px;
    }

    .isOutOfStock{
        font-size: inherit;
    }

    //products-details.scss
    .product-details {
        padding-top:0;
        .family {
            font-size: 2.8rem;
        }
        .aesthetics {
            font-size: 2.2rem;
        }
        .code {
            font-size: 1.6rem;
        }
        .product-details__infos {
            .product-details__info__type {
                font-size: 0.8rem;
            }
            .product-details__info__value {
                font-size: 1.2rem;
            }
        }
        .product-details__tags .product-details__tag__item {
            font-size: 0.8rem;
        }
        .product-details__slider {
            .slider-arrow img {
                width: 20px;
            }
            .slider-arrow.slick-prev {
                left: 0;
            }
            .slider-arrow.slick-next {
                right: 0;
            }
        }
    }
 

    .product-detail {
        .product-detail__title {
            font-size: 2.1rem;
        }
        .product-detail__content .detail_icons .icon-30 {
            max-width: 30px;
        }
        .product-detail__content {
            .details {
                .detail__label {
                    font-size: 0.8rem;
                }
                .detail__txt {
                    font-size: 1rem;
                }
            }
            .detail-list__list__li {
                img {
                    max-width: 20px;
                }
            }
        }
    }
    .downloadTab .item .title {
        font-size: 1.6rem;
    }

    //related-products.scss
    .related-products-component .section-title {
        font-size: 2rem;
    }
    .related-products-component .slider-cards-component__slide .cards__textBox {
        .cards__title {
            font-size: 1.3rem!important;
        }
        .cards__sub_title {
            font-size: 1.6rem;
        }
    }
    //text-component.scss
    .asymmetricText {
        .asymmetricText__title h4 {
            font-size: 2.1rem;
        }
        .asymmetricText__textBody,
        .asymmetricText__textBody p {
            font-size: 1rem;
            line-height: 18px;
        }
    }

    .generalText {
        .generalText__title {
            font-size: 2.1rem;
        }
        .generalTextFocus__textBlock p {
            font-size: 2rem;
        }
    }

    //tipography.scss
    .button-primary {
        font-size: 1rem;
    }

    //tab.scss
    .reference-tab-component .tab-component li.tab-component__button .tab-component__button__title {
        font-size: 0.8rem;
    }

    //products-comparator.scss
    .comparator-container {
        padding: 10px 0;
    }

    //banner-slider.scss
    .bannersSlider {
        .slick-dots li button {
            border-bottom: 2px solid #b4b4b4;
        }
        .sliders-texts .slider__title {
            font-size: 2.1rem;
        }
    }

    //related-item.scss
    .relatedItem__header--title,
    .relatedItems__header--title {
        font-size: 2rem;
    }
    .relatedItem__title,
    .relatedItems__title {
        font-size: 1.6rem;
    }
    .relatedItem__introduction,
    .relatedItems__introduction {
        font-size: 1.2rem;
    }

    //history.scss
    .historyComponent{
        .slider__item:first-of-type .historyComponent__slide:first-of-type .historyComponent__textBlock .historyComponent__title{
            font-size: 3rem;
        }
        .historyComponent__title {
            font-size: 3rem;
        }

    }

    //promotions-list.scss
    .promotion{
        .promotions__category{
            font-size: 1.2rem;
        }
    }

    //award.scss
    .award__sliderNav{
        .nav .nav-item {
            .nav-link{
                font-size: 1.4rem;
            }
            .nav-link.active {
                font-size: 4rem;
            }
        }
    }
    .award__sliderNav--yearText{
        font-size: .8rem
    }

    //eform.scss
    .eform-component{
        .eform-component__block>h4{
            font-size: 2.1rem;
        }
        .eform-component__block .form-group>div label{
            font-size: .8rem;
        }
        .eform-component__block{
            .form-group input{
                font-size: 1.3rem;
            }
            .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered{
                font-size: 1.3rem;
            }
        }
        .eform-component__block .form-group.checkboxgroup label{
            font-size: .9rem;
        }
        .eform-component__block .eforms-buttons button,
        .eform-component__block .eforms-buttons input[type=button]{
            font-size: 1rem;
        }
    }

    //search.scss
    .search__product__title{
        font-size: 2.8rem;
    }
    .search__product__result_number{
        font-size: 1.4rem;
    }

    //job-position.scss
    // .job-position-component__header__info--data{
    //     font-size: .8rem;
    // }
    .job-position-component__header .job-position-component__header__title h3{
        font-size: 2rem;
    }

    //store-locator
    .stores-locator .stores-filters__header h2{
        font-size: 2rem;
    }
    .stores-locator .stores-filters__header p{
        font-size: 1rem;
    }
    .stores-locator .stores-filters__label>p{
        font-size: .8rem;
    }
    .stores-locator .stores-filters__label>button:after{
        width: 10px;
        height: 10px;
    }
    .stores-locator .stores-list .store-detail__expand>div{
        width: 16px;
        height: 16px;
    }
    .stores-locator .stores-list .store-detail>div a, .stores-locator .stores-list .store-detail>div p{
        font-size: 1rem;
    }
    .stores-locator .stores-list .store-detail>div .action-link{
        font-size: .8rem;
    }
    .stores-locator .stores-filters .form-inline .input-group>button{
        font-size: 1rem;
    }
    .stores-locator .stores-filters select.form-control{
        font-size: 1rem;
    }
    .select-dropdown .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered{
        font-size: 1.3rem;
    }
    .stores-locator__index .grid h2{
        font-size: 2rem;
    }
    .select-dropdown .select2.select2-container .select2-selection.select2-selection--single .select2-selection__rendered{
        line-height: inherit;
    }
    .stores-locator .stores-filters .select.form-control{
        font-size: 1rem;
    }
    .stores-locator__component .anchor{
        font-size: .8rem;
    }
    .stores-locator .stores-map__zoomController>button:after{
        width: 16px;
        height: 16px;
    }
    .stores-locator .stores-map__legend>div>span{
        font-size: .8rem;
    }
    .stores-locator .stores-list__content p{
        font-size: 1rem;
    }
    .stores-locator .stores-filters__container__content>label{
        font-size: .8rem;
    }
    .stores-locator .stores-filters__container__content>.input-group .form-control{
        font-size: 1rem;
    }
    .stores-locator .stores-filters__container__content>button{
        font-size: .8rem;
    }

    /* New menu megaMenu */

    #megaMenu {
        .tpl_album.tpl_columns_3,
        .tpl_list,
        .tpl_gallery{
            ul{
                column-gap: 20px!important;
                .linkCard{
                    padding-top: 10px!important;
                    width: 100px;
                    max-width: 100px;
                    img{
                        width: auto;
                    }
                    span{
                        line-height: 1.4rem
                    }
                }
            }
        }
        .tpl_gallery{
            ul{
                .linkCard{
                    max-width: 33% !important;
                }
            }
        }
        .tpl_card{
            >.body {
                >ul {
                    > li {
                        //max-width: 50%;
                        .body {
                            ul {
                                display: flex;

                                li {
                                    max-width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .tpl_album.tpl_columns_4 .body ul.list-unstyled{
            column-gap: 20px!important;
        }

        .tpl_list{
            ul{
                .linkCard{
                    padding: 15px 0 0 0!important;
                    margin-right: 40px;
                }
            }
        }

        .mainMenu .mainMenu__rightMenu{
            right:50px;
        }

        .level_1:not(.tpl_fullWidth) {
            &>.body{
                &>ul.list-unstyled{
                    &>li:first-of-type{
                        padding-top:3%;
                    }
                }
            }
        }

    }


    /* end New menu megaMenu */

    /* Foodservice*/

    .foodservice {

        .select2-container {
            height: 29px !important;
            display: block;
            .select2-selection--single {
                height: 29px !important;
                .select2-selection__arrow {
                    height: 29px !important;
                }
            }
        }
        #ssc-form .eform-component .selectize-control.form-control.single {
            height: 30px;
        }
        #ssc-form .eform-component .selectize-input {
            padding: 5px 0;
        }

    }

}
